import Vue from 'vue'
import VueRouter from 'vue-router'
import page404 from '../views/pages/404.vue';
import page500 from '../views/pages/500.vue';
import Main from '../views/Main.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '/',
        redirect: 'tables',
      },
      {
        path: 'tables',
        name: 'Tables',
        meta: {
          order: 0,
          collection: 'dining',
        },
        component: Main,
      },
      {
        path: 'chairs',
        name: 'Chairs',
        meta: {
          order: 1,
          collection: 'dining',
        },
        component: Main,
      },
      {
        path: 'barstools',
        name: 'Barstool',
        meta: {
          order: 2,
          collection: 'dining',
        },
        component: Main,
      },
      {
        path: 'benches',
        name: 'Benches',
        meta: {
          order: 3,
          collection: 'dining',
        },
        component: Main,
      },
      {
        path: 'cases',
        name: 'Cases',
        meta: {
          order: 4,
          collection: 'dining',
        },
        component: Main,
      },
      {
        path: 'occasional',
        name: 'Occasional',
        meta: {
          order: 0,
          collection: 'occasional',
        },
        component: Main,
      },
      {
        path: 'bedroom',
        name: 'Bedroom',
        meta: {
          order: 0,
          collection: 'bedroom',
        },
        component: Main,
      },
      {
        path: 'desk',
        name: 'Desk',
        meta: {
          order: 0,
          collection: 'homeOffice',
        },
        component: Main,
      },
      {
        path: 'cabinet',
        name: 'File Cabinet',
        meta: {
          order: 1,
          collection: 'homeOffice',
        },
        component: Main,
      },
      {
        path: 'bookcase',
        name: 'Bookcase',
        meta: {
          order: 2,
          collection: 'homeOffice',
        },
        component: Main,
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: page404,
    meta: {
      requiresAuth: false,
      title: '404 page',
    },
  },
  {
    path: '/500',
    name: '500',
    component: page500,
    meta: {
      requiresAuth: false,
      title: '500 page',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
