<template>
  <div
    v-if="baseId !== ''"
    class="col-lg-8 render-container"
  >
    <canvas id="canvas"/>
    <div class="dp-controls">
      <div class="dp-controls-view">
        <font-awesome-icon
          :icon="isShowControls ? 'angle-double-left' : 'angle-double-right'"
          class="dp-controls-tool"
          @click="isShowControls = !isShowControls"
        />
      </div>
      <div
        v-show="isShowControls"
        class="dp-controls-group"
      >
        <font-awesome-icon
          class="dp-controls-tool"
          icon="camera-retro"
          @click="takeScreenshot"
        />
        <font-awesome-icon
          :icon="isRotate ? 'pause-circle' : 'play-circle'"
          class="dp-controls-tool"
          @click="rotate"
        />
        <font-awesome-icon
          class="dp-controls-tool"
          icon="search-plus"
          @click="startAction('zoom', 'in')"
        />
        <font-awesome-icon
          class="dp-controls-tool"
          icon="search-minus"
          @click="startAction('zoom', 'out')"
        />
        <font-awesome-icon
          class="dp-controls-tool"
          icon="home"
          @click="resetZoom()"
        />
        <font-awesome-icon
          class="dp-controls-tool"
          icon="undo"
          @click="startAction('rotate', 'left')"
        />
        <font-awesome-icon
          class="dp-controls-tool"
          icon="redo"
          @click="startAction('rotate', 'right')"
        />
      </div>
    </div>
    <div
      v-if="!isModelExists"
      class="file-status"
    >
      <span>The 3D Model is not <br>yet available for this product.</span>
    </div>
    <div
      v-if="!isSwatchExists"
      class="file-status"
    >
      <span>The Swatch texture does not exist.</span>
    </div>
    <div
      v-if="isLoading"
      class="screen-loading"
    >
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :value="loadingPercentage"
        :width="5"
        color="white"
      >
        {{ `${loadingPercentage}%` }}
      </v-progress-circular>
    </div>
  </div>
</template>
<script>

import {mapGetters} from "vuex";
// import {GLTFLoader} from './GLTFLoader';
import * as THREE from 'three';
import {Box3, Vector3} from 'three';
import {OrbitControls} from './OrbitControls';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {TableLegDeflects} from '../../constants/ModelSettings';
import {
  FINISH_OPTIONS,
  PRODUCT_LINES,
  SELECT_LABELS,
  TEXTURE_PATHS,
  UPHOLSTERY_OPTIONS,
} from "../../constants/RenderConstants";

export default {
  name: "ModelRender",
  props: {
    baseId: {
      type: String,
      default() {
        return '';
      }
    },
    df: {
      type: String,
      default() {
        return '';
      }
    },
    extraDf: {
      type: String,
      default() {
        return '';
      }
    },
    extraOption: {
      type: Array,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      default() {
        return '';
      }
    },
    productLine: {
      type: String,
      default() {
        return '';
      }
    },
    value: {
      type: String,
      default() {
        return '';
      }
    },
     /**
     * Set `true` at edit mode
     */
     isEditMode: Boolean,
  },
  data() {
    return {
      deflects: TableLegDeflects,
      animateId: null, // - scene
      camera: null,
      cameraDefault: new Vector3(),
      container: null,
      controls: null,
      cameraControls: null,
      model: null,
      prevModel: null,
      leafPath: '',     // Path for the 3D model of the leaf component
      legPath: '',      // Path for the 3D model of the leg component
      modelPath: '',    // Path for the 3D model of the main product
      pedPath: '',      // Path for the 3D model of the pedestal component
      texturePath: '',  // Path for the texture image used for the product
      renderer: null,
      scene: null, // scene -
      apronMat: null, // - mat
      archMat: null,
      backMat: null,
      baseMat: null,
      caseMat: null,
      chairMat: null,
      cornerMat: null,
      cushionMat: null,
      doorMat: null,
      drawerMat: null,
      headsMat: null,
      inlayMat: null,
      insideMat: null,
      knobMat: null,
      leafApronMat: null,
      leafMat: null, // table
      pedMat: null,
      railMat: null,
      seatMat: null,
      topMat: null,
      frameMat: null,
      textureLoader: null,
      archTexture: null,
      backTexture: null,
      baseTexture: null,
      bedroomTexture: null,
      chairTexture: null,
      cornerTexture: null,
      headsTexture: null,
      inlayTexture: null,
      leafTexture: null,
      railTexture: null,
      seatTexture: null,
      topTexture: null,
      woodTexture: null, // mat -
      ARMS: null, // - control
      CASE: null,
      BACK: {
        'Inlay': null,
        'NoInlay': null,
      },
      CORNER: null,
      CUSHION: null,
      HARDWARE: {
        'DOOR': [],
        'DRAWER': [],
        'MIDDLE': [],
        'TOP': [],
      },
      INITIAL_HARDWARE: [],
      INITIAL_MODEL: '',
      NAILHEADS: null,
      NATURALE: null,
      SEAT: null,
      SHADOW: null,
      LEAVE: {
        '112': [],
        '118': [],
        '212': [],
      },
      LEG: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      PED: {},
      PED_CLONE: {},
      PLANE: {},
      PLANE_CLONE: {},
      TOP: {},
      TOPCLONE: {},
      topBoundingMax: new Box3(),
      topBoundingMin: new Box3(),
      moveLeafX: 0,
      TOP0: new Vector3(),
      LEG0: new Vector3(),
      finishOptionName: null,
      isBackExist: false,
      isModelExists: true,
      isSwatchExists: true,
      isRotate: false,
      isLoading: true,
      isShowControls: true,
      selectUpholstery: '',
      speciesName: '',
      arms: '02',
      base: '',
      baseDF: '',
      addDF: '',
      bedroomMake: '',
      caseMake: '',
      occasionalMake: '',
      edge: 'EA',
      knob: '',
      leg: '01',
      leaf: '000',
      ped: '',
      shape: 'A',
      tableHeight: '00',
      topHeight: 0,
      width: 450,
      height: 700,
      loadingAmount: 0,
      loadingTotal: 0, // control -
    };
  },
  computed: {
    ...mapGetters([
      'initialModels',
      'notifications',
    ]),
    loadingPercentage() {
      const percentage = this.loadingAmount / this.loadingTotal * 100;
      return this.loadingAmount === 0 ? 0 : Math.round(percentage);
    },
    isLegsLoaded() {
      let isLoaded = false;

      this.scene.traverse(function (object) {
        if (object.isMesh && object.name.substring(0, 5) === 'BASE_') {
          isLoaded = true;
        }
      });

      if (['BOULDER TABLE', 'SYDNEY STORAGE TABLE'].includes(this.baseId)) {
        isLoaded = true;
      }

      return isLoaded;
    },
    isPedestalBase() {
      return this.ped && ['M', 'P'].includes(this.ped.substring(0, 1));
    },
    /**
     * @return {boolean} true if selected table is from the specific list.
     */
    isSpecificTable() {
      const tablesList = ['BOULDER TABLE', 'COPPER TOP TABLE', 'DROP LEAF TABLE', 'LIVE EDGE TABLE',
        'NATURALE TABLE', 'PLANK TABLE', 'SYDNEY STORAGE TABLE', 'ZINC TOP TABLE', 'OSLO TABLE'];
      return this.productLine === PRODUCT_LINES.TABLE && tablesList.includes(this.baseId);
    },
    /**
     * @return {boolean} true if selected table is from the specific top materials list.
     */
    isSpecificTableTopMaterial() {
      const tablesList = ['COPPER TOP TABLE', 'ZINC TOP TABLE'];
      return this.productLine === PRODUCT_LINES.TABLE && tablesList.includes(this.baseId);
    },
    isUpholsteryBack() {
      const upholstery = ['Wood Seat & Fabric Back', 'Wood Seat & Leather Back',
        UPHOLSTERY_OPTIONS.FABRIC_SEAT_BACK, UPHOLSTERY_OPTIONS.LEATHER_SEAT_BACK];
      return upholstery.includes(this.selectUpholstery);
    },
  },
  watch: {
    NATURALE() {
      this.tableTopPosition(this.ped);
    },
    /**
     * Watch baseId mutation
     *
     * @param {string} val
     */
    baseId(val) {
      if (val !== '') {
        // reset scene if loads a different model
        this.resetScene();
        this.initialLoadingModel();
      }
    },
    isLoading(val) {
      this.$emit('onTrackModelLoading', val);
    },
    /**
     * React to changes in the provided value, triggering notifications and material changes as needed.
     *
     * @param {string} val - The value to watch and process.
     */
    value(val) {

      console.log("VAL ", val);

      val = val.replace('/', '_');
      // Check and emit notifications based on conditions
      this.notifications.forEach((n) => {
        // TODO: FIX THIS
        if (this.baseDF === 'AUSC28') {
          return;
        }
        const c = JSON.parse(n.condition);
        if (n.status
          && val.includes(c.condition)
          && c.selects.includes(this.label)
        ) {
          this.$emit('showNotifications', {title: n.title, content: n.content});
        }
      })
      // Handle special case for 36* & Oval tables
      if (this.baseDF.includes('36') && val.includes('Oval')) {
        this.$emit('showNotifications', {
          title: 'About 36" Wide Oval Leg Table',
          content: '36" Wide Oval Leg table is too narrow to fit any MAVIN chair on the ends.',
        });
      }


      console.log("Label123 ", this.label, " - ", val, " - ", this.df);

      // Change materials based on the provided value
      this.changeMaterials(val, this.label, this.df, false);

      if (this.extraOption.length > 0) {
        this.extraOption.forEach(option => {
          // disable the last extra selection for 'No Finish'
          if (option.V !== 'No Finish') {
            this.changeMaterials(option.V, option.label, option.DF, true);
          }
        })
      }
    },
  },
  mounted() {
    this.init(); // reset 3D scene
    this.render();
    window.addEventListener('resize', this.onWindowResize, false);
    this.$root.$on('saveOrderImage', (isSimilarChair, option, description) => {
      this.saveOrderImage(isSimilarChair, option, description);
    });
  },
  beforeDestroy() {
    this.unmountScene();
    window.removeEventListener('resize', this.onWindowResize, false);
  },
  methods: {
    /**
     * Change the materials of the 3D model based on user selection.
     * This function handles the logic of updating the 3D model's appearance based on various parameters.
     *
     * @param {string} val      - The value representing the user's selection.
     * @param {string} label    - The label of the option selected by the user.
     * @param {string} df       - Descriptor fragment, default is an empty string.
     * @param {boolean} isExtra - Indicates if the option is an extra feature.
     */
    async changeMaterials(val, label, df = '', isExtra = false) {

        console.log("Change Materials Label ", label);

      try {
        console.log(
          'ProductLine: ',  this.productLine,
          'V:',             val,
          'LABEL:',         label,
          '\nDF:',          df,
          'baseDF:',        this.baseDF,
          '\nID:',          this.baseId,
          '\nextraDf:',     this.extraDf,
          'extraLabel:',    this.extraOption['label'],
          'isExtra:',       isExtra,
        );

        switch (label) {
          case SELECT_LABELS.SELECT_LINE:
            await this.handleSelectLine(val);
            this.baseDF = df;
            break;
          case SELECT_LABELS.SELECT_SHAPE:
          case SELECT_LABELS.SELECT_TABLE_SHAPE:
            await this.handleSelectShape(df);
            break;
          case SELECT_LABELS.SELECT_LEAF_OPTION:
          case SELECT_LABELS.SELECT_LEAVES:
            this.handleSelectLeaf(df);
            break;
          case SELECT_LABELS.SELECT_EDGE:
            this.handleSelectEdge(df, isExtra);
            break;
          case SELECT_LABELS.SELECT_TABLE_SIZE:
            await this.handleSelectTableSize();
            break;
          case SELECT_LABELS.SELECT_ARM_OR_SIDE:
          case SELECT_LABELS.SELECT_STYLE:
          case SELECT_LABELS.SELECT_BEDROOM_MAKE:
          case SELECT_LABELS.SELECT_TOP_PROFILE:
            console.log("SELECT_TOP_PROFILE");
            await this.handleProductLineSelection(df, val);
            break;
          case SELECT_LABELS.SELECT_CASE_MAKE:
          case SELECT_LABELS.SELECT_OCCASIONAL_MAKE:
            // Currently, it doesn't have any implementation. It's a placeholder for future updates.
            this.occasionalMake = this.df;

            console.log("occasionalMake ", this.occasionalMake);

            if (this.INITIAL_HARDWARE['MODEL']) {
              this.modelPath = `/models/${this.productLine}/${this.baseDF}${this.occasionalMake}${this.INITIAL_HARDWARE['MODEL']}.glb`;
              // Reset the scene and load the new model
              this.resetScene();
              console.log("poziv 1 ", this.baseDF, this.df);
              this.loadModel(this.modelPath);
            }

            break;
          case SELECT_LABELS.SELECT_BASE:

            console.log("Select Base123 ", df, val);

            await this.handleSelectBase(df, val);
            break;
          case SELECT_LABELS.SELECT_LENGTH:
          case SELECT_LABELS.SELECT_CASE_MODEL:
          case SELECT_LABELS.SELECT_BEDROOM_MODEL:
          case SELECT_LABELS.SELECT_OCCASIONAL_MODEL:
          case SELECT_LABELS.SELECT_MIRROR_MODEL:
            switch (this.productLine) {
              case PRODUCT_LINES.BEDROOM:
                this.modelPath = `/models/${this.productLine}/${this.baseDF}${this.bedroomMake}${this.df}.glb`;
                break;
              case PRODUCT_LINES.BENCHES:
                // Set the model path for the "benches" product line, considering different baseDF values
                this.modelPath = this.getBenchesModelPath(this.baseDF, this.df, label);
                break;
              case PRODUCT_LINES.DINING_CASES:
              case PRODUCT_LINES.OCCASIONAL:
                // Set the model path for the "dining cases" and "occasional" product lines
                this.modelPath = this.getDiningCasesOccasionalModelPath(val, this.baseDF, this.df, this.extraDf);
                break;
              default:
                break;
            }
            // Reset the scene and load the new model
            if (this.modelPath) {
              this.resetScene();
              console.log("poziv 1123123 ", this.baseDF, this.df, label);
              // if(this.isEditMode) {
                setTimeout(() => {
                  this.loadModel(this.modelPath);
                }, 200);
              // } else {
              //   this.loadModel(this.modelPath);
              // }
            }
            break;
          case SELECT_LABELS.SELECT_BASE_SPECIES:
          case SELECT_LABELS.SELECT_SPECIES:
            this.texturePath = `${TEXTURE_PATHS.SPECIES}/${val}.jpg`;
            this.speciesName = val;
            this.handleSpeciesSelection(this.speciesName);
            break;
          case SELECT_LABELS.SELECT_HARDWARE:
          case SELECT_LABELS.SELECT_CORNER_HARDWARE:
          case SELECT_LABELS.SELECT_DECO_HARDWARE:
            await this.handleHardwareSelection();
            break;
          case SELECT_LABELS.SELECT_DOOR_HARDWARE:
            this.toggleKnob('DOOR', this.df);
            break;
          case SELECT_LABELS.SELECT_DRAWER_HARDWARE:
          case SELECT_LABELS.SELECT_OCCASIONAL_HARDWARE:

          console.log("SELECT_OCCASIONAL_HARDWARE");

            this.toggleKnob('DRAWER', this.df);
            break;
          case SELECT_LABELS.SELECT_TOP_DRAWER_HARDWARE:
            this.toggleKnob('TOP', this.df);
            break;
          case SELECT_LABELS.SELECT_MIDDLE_DRAWER_HARDWARE:
            this.toggleKnob('MIDDLE', this.df);
            break;
          case SELECT_LABELS.SELECT_SEAT_TYPE:
          case SELECT_LABELS.SELECT_SEAT_AND_BACK_OPTION:
            this.handleSeatAndBackOptionSelection(val);
            break;
          case SELECT_LABELS.SELECT_UPHOLSTERY:
          case SELECT_LABELS.SELECT_FABRIC:
            if(this.isEditMode) {
              setTimeout(() => {
                this.handleUpholsterySelection(val);
              }, 300);
            } else {
              this.handleUpholsterySelection(val);
            }

            break;
          case SELECT_LABELS.SELECT_FINISH_OPTION:
          case SELECT_LABELS.SELECT_FINISH_OPTIONS:

          console.log("FinishFinish 1");

            this.finishOptionName = val;
            console.log(this.finishOptionName);
            if (this.finishOptionName.toLowerCase().includes('stn') && this.finishOptionName.toLowerCase().includes('stain')) {
              this.finishOptionName = FINISH_OPTIONS.SOLID_STAIN;
            } else if (this.finishOptionName.toLowerCase().includes('pnt') && this.finishOptionName.toLowerCase().includes('paint')) {
              this.finishOptionName = FINISH_OPTIONS.SOLID_PAINT;
            }

            break;
          case SELECT_LABELS.SELECT_FINISH:
          case SELECT_LABELS.SELECT_FINISH_ACCENT:

          console.log("FinishFinish 2");

            const isElmDoorsSpecies = this.speciesName.toLowerCase().includes('elm') &&
              this.speciesName.toLowerCase().includes('doors');
            const roughness = this.finishOptionName === FINISH_OPTIONS.SOLID_PAINT ? .25 : .1;

            if(this.isEditMode) {
              setTimeout(() => {
                this.handleFinishCases(val);
                this.setFinishMaterials(val, isElmDoorsSpecies, roughness);
              }, 200);
            } else {
              this.handleFinishCases(val);
              this.setFinishMaterials(val, isElmDoorsSpecies, roughness);
            }

            break;
          case SELECT_LABELS.SELECT_SEAT_FINISH:
          case SELECT_LABELS.SELECT_ACCENT_FINISH:
          case SELECT_LABELS.SELECT_FIRST_FINISH:
          case SELECT_LABELS.SELECT_TOP_FINISH:
          case SELECT_LABELS.SELECT_DOORS_ONLY:
          case SELECT_LABELS.SELECT_DOORS_AND_DRAWERS_ONLY:
          case SELECT_LABELS.SELECT_DRAWERS_ONLY:
          case SELECT_LABELS.SELECT_FLOATING_PANEL_ONLY:
          case SELECT_LABELS.SELECT_FLOATING_PANEL_DRAWERS:
          case SELECT_LABELS.SELECT_SEAT_AND_TOP_RAIL:
          case SELECT_LABELS.SELECT_TOP_AND_DRAWERS:
          case SELECT_LABELS.SELECT_TOP_AND_DOORS:
          case SELECT_LABELS.SELECT_TOP_RAIL_ONLY:
          case SELECT_LABELS.SELECT_TOP_DOORS_DRAWERS:
          case SELECT_LABELS.SELECT_TOP_DOORS_DRAWERS_C:
          case SELECT_LABELS.SELECT_HEADBOARD_PANEL_ONLY:
          case SELECT_LABELS.SELECT_HEADBOARD_AND_FOOTBOARD_PANEL:
          case SELECT_LABELS.SELECT_HEADBOARD_FOOTBOARD_PANEL_AND_DRAWERS:
            // Handling special cases in finish names
            val = val.replace(/- Burnishing| BUR|- Paint Rub Thru| PRT|- Savannah Glaze & Paint Rub Thru| SAV_PRT/g, '');

            // Detect if it's a paint swatch selecting in 2TT options
            if (!this.isWoodStain(val)) {
              this.texturePath = `${TEXTURE_PATHS.PAINT}/${val}.jpg`;
              this.chairTexture = this.loadAndSetTexture(this.texturePath);
              this.railTexture = this.loadAndSetTexture(this.texturePath);
              this.seatTexture = this.loadAndSetTexture(this.texturePath);
            } else {
              // For wood stain options, handle special cases for Elm/Maple
              this.handleElmMapleCases(val);
            }

            // Set materials for the first finish option
            this.setFirstFinishMaterials();

            break;
          case SELECT_LABELS.SELECT_FRAME_FINISH:
          case SELECT_LABELS.SELECT_BODY_FINISH:
          case SELECT_LABELS.SELECT_SECOND_FINISH:
          case SELECT_LABELS.SELECT_BASE_FINISH:
          case SELECT_LABELS.SELECT_INSIDE_FINISH:
            // Handling special cases in finish names
            val = val.replace(/- Burnishing| BUR|- Paint Rub Thru| PRT|- Savannah Glaze & Paint Rub Thru| SAV_PRT/g, '');

            // detect paint swatch selecting in 2TT options
            if (!this.isWoodStain(val)) {
              this.texturePath = `${TEXTURE_PATHS.PAINT}/${val}.jpg`;
              this.caseTexture = this.loadAndSetTexture(this.texturePath);
              this.chairTexture = this.loadAndSetTexture(this.texturePath);
              this.railTexture = this.loadAndSetTexture(this.texturePath);
              this.seatTexture = this.loadAndSetTexture(this.texturePath);
            } else {
              // Elm Seat & Maple Frame
              if (this.speciesName.indexOf('Elm') !== -1) {
                this.texturePath = `${TEXTURE_PATHS.ELM}/${val}_Elm.jpg`;
                const mapleTexture = `${TEXTURE_PATHS.MAPLE}/${val}_Maple.jpg`;

                this.chairTexture = this.loadAndSetTexture(mapleTexture);
                this.railTexture = this.loadAndSetTexture(mapleTexture);

                switch (this.speciesName) {
                  case 'Elm_Maple':
                  case 'Elm Top_Maple':
                  case 'Elm Seat & Maple Frame':
                  case 'Elm (Top, Doors & Drawers)_Maple':
                  case 'Elm (Top, Doors, & Drawers)_Maple':
                    this.seatTexture = this.loadAndSetTexture(this.texturePath);
                    // dining case
                    this.caseTexture = this.loadAndSetTexture(mapleTexture);
                    break;
                  case 'Elm Seat_Top Rail & Maple Frame':
                    this.railTexture = this.loadAndSetTexture(this.texturePath);
                    this.seatTexture = this.loadAndSetTexture(this.texturePath);
                    break;
                  case 'Elm Top Rail Only w_ Maple Frame':
                    this.railTexture = this.loadAndSetTexture(this.texturePath);
                    this.seatTexture = this.loadAndSetTexture(mapleTexture);
                    break;
                }
              } else {
                this.texturePath = `${TEXTURE_PATHS.DEFAULT}/${this.speciesName}/${val}_${this.speciesName}.jpg`;
                this.chairTexture = this.loadAndSetTexture(this.texturePath);
                this.railTexture = this.loadAndSetTexture(this.texturePath);
                this.seatTexture = this.loadAndSetTexture(this.texturePath);
                this.caseTexture = this.loadAndSetTexture(this.texturePath);
              }
            }

            // Set materials for the second finish option.
            this.setSecondFinishMaterials(val);

            break;
          case SELECT_LABELS.SELECT_NAIL_HEAD:
          case SELECT_LABELS.SELECT_NAILHEAD:
            if (this.NAILHEADS) {
              this.NAILHEADS.visible = !(val === '*No Nailhead' || val === '*No Nail Head');
            }

            if (this.headsMat) {
              this.texturePath = `${TEXTURE_PATHS.NAIL_HEADS}/${val}.jpg`;
              this.headsTexture = this.prepareTexture(this.texturePath, 50, 50, 0, 0);
              this.setTextureMaterial(this.headsMat, this.headsTexture, 0.2, 0.9);
            }

            break;
          case SELECT_LABELS.SELECT_INLAY:
            if (this.BACK['NoInlay']) {
              this.BACK['NoInlay'].visible = true;
              this.BACK['Inlay'].visible = false;
              if (this.df === '1' || ['1', '-1'].includes(this.extraDf)) {
                this.BACK['NoInlay'].visible = false;
                this.BACK['Inlay'].visible = true;
              }
            }

            break;
        }

      } catch (e) {
        this.isLoading = false;
        console.log(e.message);
        console.warn(`Exists any changing material problem at ${label} | ${e}`)
      }
    },
    /**
     * Loads and sets a texture with the specified path and settings.
     *
     * @param {string} path             - The path to the texture image file.
     * @param {number} rotation         - The rotation angle (in degrees) to apply to the texture (default is 0).
     * @param {boolean} repeatWrapping  - A flag to enable or disable texture repeating (default is true).
     * @returns {THREE.Texture}         - The loaded and configured texture object.
     */
    loadAndSetTexture(path, rotation = 0, repeatWrapping = true) {
      console.log("textureLoad ", path);
      let texture = this.textureLoad(path);

      // Set the encoding for the texture to sRGB for correct color representation
      // texture.colorSpace = THREE.LinearSRGBColorSpace;
      texture.encoding = THREE.sRGBEncoding;

      // Set the wrap mode for the texture to repeat, allowing it to wrap around objects
      if (repeatWrapping) {
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
      }

      // Apply rotation to the texture, if specified
      texture.rotation = THREE.MathUtils.degToRad(rotation);

      return texture;
    },
    /**
     * Load and prepare a texture for usage.
     *
     * @param {string} path - The path to the texture file.
     * @param {number} repeatS - The repeat factor along the horizontal axis.
     * @param {number} repeatT - The repeat factor along the vertical axis.
     * @param {number} offsetX - The offset along the horizontal axis.
     * @param {number} offsetY - The offset along the vertical axis.
     * @param {boolean} flipY - The texture is flipped along the vertical axis when uploaded to the GPU.
     * @param {number} rotationDegrees - How much the texture is rotated around the center point, in radians.
     *
     * @returns {Object} The prepared texture.
     */
    prepareTexture(path, repeatS = 1, repeatT = 1, offsetX = 0, offsetY = 0, flipY = true, rotationDegrees = 0) {
      console.log("textureLoad");
      let texture = this.textureLoad(path);
      // Set the encoding for the texture to sRGB for correct color representation
      // texture.colorSpace = THREE.SRGBColorSpace;
      texture.encoding = THREE.sRGBEncoding;
      // Set the wrap mode for the texture to repeat, allowing it to wrap around objects
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(repeatS, repeatT);
      texture.offset.set(offsetX, offsetY);
      texture.flipY = flipY;
      // If a rotation is required
      if (rotationDegrees !== 0) {
        texture.rotation = THREE.MathUtils.degToRad(rotationDegrees);
        texture.updateMatrix();
      }

      return texture;
    },
    /**
     * Handles the 'Select Line' case.
     * @param {string} val - The value representing the user's selection
     */
    async handleSelectLine(val) {
      this.moveLeafX = 0;

      if (this.productLine === PRODUCT_LINES.TABLE && this.baseId.includes('X- T')) {
        this.shape = 'A';
        this.leaf = '000';
        this.leg = '01';
        this.edge = 'EA';
        this.ped = '';
        this.speciesName = val.substring(5);
        this.texturePath = `${TEXTURE_PATHS.SPECIES}/${this.speciesName}.jpg`;
        this.baseTexture = this.leafTexture = this.topTexture = this.loadAndSetTexture(this.texturePath);
      }

      this.controls.minPolarAngle = 0;
      this.controls.maxPolarAngle = Math.PI;
      this.controls.update();
    },
    /**
     * Handles the 'Select Shape' or 'Select Table Shape' case.
     *
     * @param {string} df - The selected shape value.
     */
    async handleSelectShape(df) {
      this.shape = df;
      this.leaf = '000';
      this.ped = this.INITIAL_HARDWARE['BASE'];
      try {
        this.resetScene();
        if (!this.isSpecificTable && this.baseDF) {
          const shapePrefix = ['E', 'K'].includes(this.shape)
            ? `${this.baseDF.substring(0, 2)}${this.shape}`
            : this.baseDF.substring(0, 2);
          this.leafPath = `/models/${this.productLine}/${shapePrefix}.glb`;
          console.log("poziv 1");
          await this.loadModel(this.leafPath);
        }

        if (this.baseDF && this.shape) {
          this.modelPath = `/models/${this.productLine}/${this.baseDF}${this.shape}.glb`;
          console.log("poziv 1");
          await this.loadModel(this.modelPath);
        }
        if (!this.isSpecificTable) {
          this.legPath = `/models/${this.productLine}/Legs.glb`;
          console.log("poziv 1");
          await this.loadModel(this.legPath);
        }

      } catch (e) {
        console.warn(`Select Shape | ${e}`);
      }
    },
    /**
     * Handles the 'Select Leaf Option' or 'Select Leaves' case.
     *
     * @param {string} df - The selected shape value.
     */
    handleSelectLeaf(df) {
      this.leaf = df.substring(0, 3);
      if (!this.isSpecificTable) {
        this.resetTable(this.leaf);
      }
      this.toggleLeaf();
    },
    /**
     * Handles the 'Select Edge' case.
     *
     * @param {string} df - The selected edge value.
     * @param {boolean} isExtra - Indicates if the selected edge is an extra.
     */
    handleSelectEdge(df, isExtra) {
      // Set the edge value based on the selected edge ('df') or default to 'EA' if 'isExtra' is true
      this.edge = this.df.substring(0, 1) === 'E' ? this.df : `E${this.df}`;
      if (isExtra) {
        this.edge = df;
      }

      // If 'edge' is 'E', set it to 'EA' (missing DESC_FRAG value of edge)
      if (this.edge === 'E') {
        this.edge = 'EA';
      }

      // Toggle the selected edge
      this.toggleEdge(this.edge);
    },
    /**
     * Handles the 'Select Table Size' case.
     */
    async handleSelectTableSize() {

      console.log("handleSelectTableSize");

      this.resetScene();
      this.baseDF = this.df;
      this.base = this.base || 'P1';
      this.ped = this.INITIAL_HARDWARE['BASE'];
      this.pedPath = `/models/${this.productLine}/${this.ped}.glb`;

      // Plank table solid round
      if (this.baseDF.indexOf('PB') !== -1) {
        this.edge = 'EE';        
        this.ped = 'PN'; // Changed default base for Plank Table - Solid Round to P1N Sophia
        this.pedPath = `/models/${this.productLine}/${this.ped}.glb`;
      }

      if (!['BOULDER TABLE', 'SYDNEY STORAGE TABLE'].includes(this.baseId)) {
        // load the pedestal model if initial base is located.
        if (this.isPedestalBase) {
          console.log("poziv 1");
          await this.loadModel(this.pedPath);
        } else {
          this.legPath = `/models/${this.productLine}/Legs.glb`;
          console.log("poziv 1");
          await this.loadModel(this.legPath);
        }
      }

      // Check for Breadboard Leaves and modify baseDF
      if (this.value.includes('Breadboard')) {
        this.baseDF += 'B';
      }

      // Load table-top model for specific tables
      if (['BOULDER TABLE', 'PLANK TABLE', 'DROP LEAF TABLE'].includes(this.baseId)) {
        this.modelPath = `/models/${this.productLine}/${this.baseDF}.glb`;
        console.log("poziv 1");
        await this.loadModel(this.modelPath);
      }

      if (this.LEG[0].length > 0) {
        this.tableLegPosition();
      }
    },
    /**
     * Handles product line-specific selections.
     * @param {string} df - The selected product line identifier
     * @param {string} val - The value representing the user's selection
     */
    async handleProductLineSelection(df, val) {
      switch (this.productLine) {
        case PRODUCT_LINES.BARSTOOL:
          this.arms = df;
          if (this.baseDF === 'BKL') {
            this.arms = this.df + '04';
          }
          if (this.baseDF !== 'ALS28') {
            this.base = 'BC24'; // default base model.
            this.resetScene();
            this.modelPath = `/models/${this.productLine}/${this.baseDF}${this.arms}${this.base}.glb`;
            console.log("poziv 1");
            await this.loadModel(this.modelPath);
          }
          break;
        case PRODUCT_LINES.BEDROOM:
          this.bedroomMake = df.charAt(0);
          this.resetScene();
          this.modelPath = `/models/${this.productLine}/${this.baseDF}${df}.glb`;
          console.log("poziv 1");
          await this.loadModel(this.modelPath);
          break;
        case PRODUCT_LINES.BENCHES:
          this.arms = df;
          this.togglePart(val);
          break;
        default:
          // Custom logic for Dallas
          if (this.baseDF === 'DAL82') {
            this.resetScene();
            this.modelPath = `/models/${this.productLine}/${this.baseDF}${df}.glb`;
            console.log("poziv 1");
            await this.loadModel(this.modelPath);
          } else {
            this.togglePart(val);
          }
          break;
      }
    },
    /**
     * Handles the 'Select Base' case.
     * @param {string} df - The selected base identifier
     * @param {string} val - The value representing the user's selection
     */
    async handleSelectBase(df, val) {
      switch (this.productLine) {
        case PRODUCT_LINES.BARSTOOL:
          if (this.baseDF === 'SAD6604') {
            this.arms = '';
          }
          this.modelPath = `/models/${this.productLine}/${this.baseDF}${this.arms}${this.df}.glb`;
          this.resetScene();
          console.log("poziv 1");
          await this.loadModel(this.modelPath);
          break;

        case PRODUCT_LINES.TABLE:
          // remove the base shadow on the scene.
          for (const plane of Object.keys(this.PLANE)) {
            this.PLANE[plane].visible = false;
            this.scene.remove(this.PLANE[plane]);
            delete this.PLANE[plane];
          }

          switch (df.substring(0, 1)) {
            case 'L':
              this.leg = df.substring(1, 3);
              this.ped = '';

              if (!this.isLegsLoaded) {
                this.legPath = `/models/${this.productLine}/Legs.glb`;
                console.log("poziv 1");
                await this.loadModel(this.legPath);
              }

              break;

            case 'M':
              this.ped = df;
              this.pedPath = `/models/${this.productLine}/${df}.glb`;
              console.log("poziv 1");
              await this.loadModel(this.pedPath);

              break;

            case 'P':
              this.base = df.substring(0, 2);
              if (df === 'P2SS') {
                this.base = 'P1';
              } else {
                this.ped = df.substring(0, 1) + df.substring(df.length - 1, df.length);
                this.pedPath = `/models/${this.productLine}/${this.ped}.glb`;
                // P1G- (2) Single Augusta
                if (val === 'P1G- (2) Single Augusta') {
                  this.ped = 'PG2';
                }
                console.log("poziv 1");
                await this.loadModel(this.pedPath);
              }

              break;

            default:
              break;
          }

          if (!this.isSpecificTable) {
            this.resetTable('000');
          }

          if (df !== 'P2SS') {
            await this.tableTopPosition(df);
          }
          this.toggleLeg(df);
          this.toggleLeaf();
          break;

        default:
          break;
      }
    },
    /**
     * This method generates the model path for the "benches" product line.
     * @param {string} baseDF - The base identifier of the bench
     * @param {string} df - The selected bench identifier
     * @param {string} label - The label representing the user's selection
     * @returns {string} - The model path for the bench
     */
    getBenchesModelPath(baseDF, df, label) {
      let arms = '02'; // Default arms value
      let modelPath = '';

      switch (baseDF) {
        case 'ARL47':
        case 'HUD24':
        case 'MRQ40':
        case 'NOR71':
        case 'SOH39':
          arms = '12';
          modelPath = `/models/${this.productLine}/${baseDF}${arms}${df}.glb`;
          break;

        case 'B246904':
        case 'NAT6504':
        case 'PSM6404':
        case 'SQR6214':
          arms = '';
          // If label is not 'Select Base', use the original df value
          if (label !== 'Select Base') {
            modelPath = `/models/${this.productLine}/${baseDF}${arms}${df}.glb`;
          }
          break;

        case 'ELN74':
        case 'JAC49':
        case 'JOR37':
        case 'KEY20':
          arms = '04';
          modelPath = `/models/${this.productLine}/${baseDF}${arms}${df}.glb`;
          break;

        case 'P':
          modelPath = `/models/${this.productLine}/${baseDF}${this.arms}${df}.glb`;
          break;

        default:
          modelPath = `/models/${this.productLine}/${baseDF}${arms}${df}.glb`;
          break;
      }

      return modelPath;
    },
    /**
     * Generates the model path for the "dining cases" and "occasional" product lines.
     * @param {string} val      - The value representing the user's selection
     * @param {string} baseDF   - The base identifier
     * @param {string} df       - The selected identifier
     * @param {string} extraDf  - The extra identifier (optional)
     * @returns {string}        - The model path for the product
     */
    getDiningCasesOccasionalModelPath(val, baseDF, df, extraDf) {
      let addDF = '';
      let modelPath = '';

      if (val === 'Standard w_ Glass Top' || val === 'Standard w_ Solid Top') {
        addDF = 'S' + df;
        modelPath = `/models/${this.productLine}/${baseDF}${addDF}.glb`;
      } else if (baseDF !== df && extraDf) {
        addDF = df + extraDf;
        modelPath = `/models/${this.productLine}/${baseDF}${addDF}.glb`;
      } else if (this.occasionalMake !== df) {
        if (baseDF === 'MONC32') {
          addDF = df;
        } else {
          addDF = this.occasionalMake + df;
        }
        modelPath = `/models/${this.productLine}/${baseDF}${addDF}.glb`;
      }

      // Kiawah Dining Case
      if (baseDF === 'KIAC29') {
        modelPath = `/models/${this.productLine}/${baseDF}${extraDf}.glb`;
      }

      // Filter Hutch top piece - 'H'
      this.caseMake = df;

      return modelPath;
    },
    /**
     * Handles the selection of a species and sets the appropriate textures for different parts of the model.
     * @param {string} speciesName - The name of the selected species.
     */
    handleSpeciesSelection(speciesName) {
      // Check if the selected species is Elm
      let isElmSpecies = speciesName.indexOf('Elm') !== -1;

      // If the species is "Elm", handle its specific textures
      if (isElmSpecies) {
        this.texturePath = `${TEXTURE_PATHS.SPECIES}/Elm.jpg`;
        this.handleElmSpeciesSelection(speciesName);
      } else {
        // Otherwise, set default species textures for non-Elm species
        this.chairTexture = this.loadAndSetTexture(this.texturePath);
        this.caseTexture = this.loadAndSetTexture(this.texturePath);
        this.railTexture = this.loadAndSetTexture(this.texturePath);
        this.seatTexture = this.loadAndSetTexture(this.texturePath);
      }

      // Set chair-specific species textures
      this.backTexture = this.loadAndSetTexture(this.texturePath);
      this.baseTexture = this.loadAndSetTexture(this.texturePath);
      this.seatTexture = this.loadAndSetTexture(this.texturePath);
      this.woodTexture = this.loadAndSetTexture(this.texturePath);
      if (this.productLine !== PRODUCT_LINES.BENCHES) {
        this.seatTexture.rotation = THREE.MathUtils.degToRad(90);
      }

      // Set the textures for the different materials based on the selected species
      this.setSpeciesMaterials();
    },
    /**
     * Handles Elm species selection and the corresponding textures
     * @param {string} speciesName - The name of the selected species
     * @returns {void}
     */
    handleElmSpeciesSelection(speciesName) {
      let elmTexturesPath = `${TEXTURE_PATHS.SPECIES}/Elm.jpg`;
      let mapleTexturesPath = `${TEXTURE_PATHS.SPECIES}/Maple.jpg`;

      switch (speciesName) {
        case 'Elm_Maple':
        case 'Elm Top_Maple':
        case 'Elm Seat & Maple Frame':
        case 'Elm (DOORS ONLY)_Maple':
        case 'Elm (Top & Doors)_Maple':
        case 'Elm (Top & Drawers)_Maple':
        case 'Elm (Top, Doors & Drawers)_Maple':
        case 'Elm (Top, Doors, & Drawers)_Maple':
          this.chairTexture = this.loadAndSetTexture(mapleTexturesPath);
          this.caseTexture = this.loadAndSetTexture(mapleTexturesPath);
          this.railTexture = this.loadAndSetTexture(mapleTexturesPath);
          this.seatTexture = this.loadAndSetTexture(elmTexturesPath);
          break;
        case 'Elm Seat_Top Rail & Maple Frame':
          this.chairTexture = this.loadAndSetTexture(mapleTexturesPath);
          this.railTexture = this.loadAndSetTexture(elmTexturesPath);
          this.seatTexture = this.loadAndSetTexture(elmTexturesPath);
          break;
        case 'Elm Top Rail Only w_ Maple Frame':
          this.chairTexture = this.loadAndSetTexture(mapleTexturesPath);
          this.railTexture = this.loadAndSetTexture(elmTexturesPath);
          this.seatTexture = this.loadAndSetTexture(mapleTexturesPath);
          break;
        default:
          this.chairTexture = this.loadAndSetTexture(elmTexturesPath);
          this.railTexture = this.loadAndSetTexture(elmTexturesPath);
          this.seatTexture = this.loadAndSetTexture(elmTexturesPath);
          break;
      }
    },
    /**
     * Set texture materials for different parts of the model based on the selected species.
     * @returns {void}
     */
    setSpeciesMaterials() {
      // Check if the back material exists and if there's no existing back texture
      if (!this.isBackExist) {
        this.setTextureMaterial(this.backMat, this.backTexture, 0.1);
      }
      // Assign textures to chair materials
      this.setTextureMaterial(this.archMat, this.chairTexture, 0.1);
      this.setTextureMaterial(this.chairMat, this.chairTexture, 0.1);
      this.setTextureMaterial(this.railMat, this.railTexture, 0.1);
      this.setTextureMaterial(this.seatMat, this.seatTexture, 0.1);
      // Saddle barstool
      if (this.baseDF === 'SAD6604') {
        this.CUSHION.visible = false;
      }
      console.log("Walnut_Inlay");
      this.inlayTexture = this.loadAndSetTexture(`${TEXTURE_PATHS.SPECIES}/Walnut_Inlay.jpg`, 90);
      this.setTextureMaterial(this.inlayMat, this.inlayTexture, 0.1);

      // Handle species-specific textures for dining case
      const isElmDrawersSpecies = this.speciesName.toLowerCase().includes('elm') &&
        this.speciesName.toLowerCase().includes('drawers');
      const isElmDoorsSpecies = this.speciesName.toLowerCase().includes('elm') &&
        this.speciesName.toLowerCase().includes('doors');
      this.setTextureMaterial(this.caseMat, this.caseTexture, 0.1);
      this.setTextureMaterial(this.doorMat, isElmDoorsSpecies ? this.woodTexture : this.caseTexture, 0.1);
      this.setTextureMaterial(this.drawerMat, isElmDrawersSpecies ? this.woodTexture : this.caseTexture, 0.1);

      // Handle species-specific textures for the table
      this.setTextureMaterial(this.apronMat, this.baseTexture, 0.1);
      this.setTextureMaterial(this.baseMat, this.baseTexture, 0.1);
      this.setTextureMaterial(this.leafApronMat, this.baseTexture, 0.1);
      this.setTextureMaterial(this.pedMat, this.woodTexture, 0.1);
      if (!this.isSpecificTableTopMaterial) {
        this.setTextureMaterial(this.topMat, this.woodTexture, 0.1);
      }

      // Handle species-specific textures for the bedroom
      this.setTextureMaterial(this.frameMat, this.woodTexture, 0.1);

      // Handle species-specific textures for the Austin case
      this.setTextureMaterial(this.insideMat, this.baseTexture, 0.1);
    },
    /**
     * Set texture material for a given material object.
     * @param {Object} material - Material object to set the texture for.
     * @param {Texture} texture - Texture object to apply to the material.
     * @param {number} roughness - Roughness value to apply to the material. Default is 1.0.
     * @param {number} metalness - Metalness value to apply to the material. Default is 0.0.
     * @returns {void}
     */
    setTextureMaterial(material, texture, roughness = 1.0, metalness = 0.0) {
      if (material) {
        material.map = texture;
        material.roughness = roughness;
        material.metalness = metalness;
        material.needsUpdate = true;
      }
    },
    /**
     * Handles the hardware selection logic for the model.
     * If the hardware selection is set to '-' (empty), the CORNER is hidden.
     * Otherwise, the CORNER is shown with the selected hardware texture applied.
     * @returns {void}
     */
    async handleHardwareSelection() {

      console.log("handleHardwareSelection ", this.df, " % ", this.extraDf);

      if (this.df === '-' || this.extraDf === '-') {
        // Hide the CORNER when hardware selection is empty
        if (this.isEditMode) {
          setTimeout(() => {
            this.CORNER.visible = false;
          }, 500);
        } else {
          this.CORNER.visible = false;
        }
      } else {
        // Show the CORNER and apply the selected hardware texture
        this.CORNER.visible = true;

        if (this.df === '63PLATE' || this.df === 'K558-BL') return;

        this.texturePath = `${TEXTURE_PATHS.CORNER}/${this.df}.jpg`;
        console.log("textureLoad ", this.texturePath, " - EDIT MODE: ");
        let texture = this.textureLoad(this.texturePath);
        // Set the encoding for the texture to sRGB for correct color representation
        texture.colorSpace = THREE.LinearSRGBColorSpace;
        // Set the wrap mode for the texture to repeat, allowing it to wrap around objects
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        // this.cornerTexture = this.prepareTexture(this.texturePath, 50, 50, 0, 0);
        if (this.isEditMode) {
          setTimeout(() => {
            this.setTextureMaterial(this.cornerMat, texture, 0.2, 0.9);
            this.setTextureMaterial(this.knobMat, texture, 0.2, 0.9);
          }, 500);
        } else {
          this.setTextureMaterial(this.cornerMat, texture, 0.2, 0.9);
          this.setTextureMaterial(this.knobMat, texture, 0.2, 0.9);
        }

      }
    },
    /**
     * Handles the selection logic for seat and back options.
     * @param {string} val - The value representing the user's selection
     * @returns {void}
     */
    handleSeatAndBackOptionSelection(val) {
      // Normalize any extra whitespace in the selected value
      val = val.replace(/\s+/g, ' ');
      this.selectUpholstery = val;

      // Choose the upholstery based on whether it's fabric or leather
      const upholstery = val.includes('Fabric') ? '104 Berlin Sandstone' : '322 Dove Grey Leather';
      this.texturePath = `${TEXTURE_PATHS.UPHOLSTERY}/${upholstery}.jpg`;

      // Determine whether the seat option is wood or upholstered and handle accordingly
      if (val.includes('Wood Seat') && this.seatMat) {
        this.handleWoodSeatOption(this.texturePath);
      } else {
        this.handleUpholsteredSeatOption(this.texturePath);
      }
    },
    /**
     * Handles the scenario of having a wood seat option.
     * @param {string} texture - The texture path for the wood seat option.
     * @returns {void}
     */
    handleWoodSeatOption(texture) {
      this.CUSHION ? this.CUSHION.visible = false : null;
      this.SEAT ? this.SEAT.visible = true : null;
      this.seatTexture = this.woodTexture;

      if (this.productLine !== PRODUCT_LINES.BENCHES) {
        this.seatTexture.rotation = THREE.MathUtils.degToRad(90);
      }

      this.setTextureMaterial(this.seatMat, this.seatTexture, 0.1);

      this.backTexture = this.prepareTexture(texture, 12, 12, 0, 0, false);
      this.setTextureMaterial(this.backMat, this.backTexture, 0.5);
    },
    /**
     * Handles the scenario of having an upholstered seat option.
     * @param {string} texture - The texture path for the upholstered seat option.
     * @returns {void}
     */
    handleUpholsteredSeatOption(texture) {
      if (this.CUSHION) {
        this.CUSHION ? this.CUSHION.visible = true : null;
        this.backTexture = this.seatTexture = this.prepareTexture(texture, 12, 12, 0, 0, false);

        this.setTextureMaterial(this.cushionMat, this.seatTexture, 0.7);
        this.setTextureMaterial(this.backMat, this.backTexture, 0.5);
      }

      if (this.SEAT) {
        this.SEAT.visible = this.productLine === PRODUCT_LINES.BARSTOOL;
        // Saddle Barstool
          this.SEAT.visible = false;
        if (this.baseDF === 'SAD6604') {
        }
      }
    },
    /**
     * Handles the selection logic for upholstery options.
     * @param {string} val - The selected value representing the upholstery option.
     * @returns {void}
     */
    handleUpholsterySelection(val) {
      // Set the texture path based on the selected upholstery option
      this.texturePath = `${TEXTURE_PATHS.UPHOLSTERY}/${val}.jpg`;

      // Set the repeatST value for the texture
      // (Specific repeatST values are set for '159 Poseidon' upholstery, otherwise use 12 as the default)
      let repeatST = val === '159 Poseidon' ? 6 : 12;

      switch (this.selectUpholstery) {
        case UPHOLSTERY_OPTIONS.WOOD_SEAT_FABRIC_BACK:
        case UPHOLSTERY_OPTIONS.WOOD_SEAT_LEATHER_BACK:
          // Hide the cushion and show the seat
          this.CUSHION ? this.CUSHION.visible = false : null;
          this.SEAT ? this.SEAT.visible = true : null;

          // Set the back texture for wood seat options
          this.backTexture = this.prepareTexture(this.texturePath, repeatST, repeatST, 0, 0, false);
          this.setTextureMaterial(this.backMat, this.backTexture, 0.5);

          // Set the seat texture to the wood texture with a rotation of 90 degrees
          this.seatTexture = this.woodTexture;
          this.seatTexture.rotation = THREE.MathUtils.degToRad(90);
          this.setTextureMaterial(this.seatMat, this.seatTexture, 0.1);
          break;

        case UPHOLSTERY_OPTIONS.FABRIC_SEAT_BACK:
        case UPHOLSTERY_OPTIONS.LEATHER_SEAT_BACK:
          // Show the cushion and set both back and seat texture for fabric/leather seat options
          this.CUSHION ? this.CUSHION.visible = true : null;
          this.backTexture = this.seatTexture = this.prepareTexture(this.texturePath, repeatST, repeatST, 0, 0, false);

          this.setTextureMaterial(this.backMat, this.backTexture, 0.7);
          this.setTextureMaterial(this.cushionMat, this.seatTexture, 0.7);
          break;

        case UPHOLSTERY_OPTIONS.FABRIC_SEAT:
        case UPHOLSTERY_OPTIONS.LEATHER_SEAT:
          // Show the cushion and set the seat texture for fabric/leather seat options
          this.CUSHION ? this.CUSHION.visible = true : null;
          this.seatTexture = this.prepareTexture(this.texturePath, repeatST, repeatST, 0, 0, false);

          if (this.SEAT) {
            this.SEAT.visible = this.productLine === PRODUCT_LINES.BARSTOOL;
            // Saddle Barstool
            if (this.baseDF === 'SAD6604') {
              this.SEAT.visible = false;
            }
          }

          // Set the back texture to wood texture and seat texture to selected upholstery
          this.setTextureMaterial(this.backMat, this.woodTexture, 0.1);
          this.setTextureMaterial(this.cushionMat, this.seatTexture, 0.5);
          break;

        default:
          // Handle specific logic for bedroom and frisco occasional when val is not 'No Upholstery'
          if ([PRODUCT_LINES.BEDROOM, PRODUCT_LINES.OCCASIONAL].includes(this.productLine) && val !== 'No Upholstery') {
            if (val.includes(' - Headboard Only')) {
              val = val.replace(' - Headboard Only', '');
            }

            this.texturePath = `${TEXTURE_PATHS.UPHOLSTERY}/${val}.jpg`;
            this.backTexture = this.prepareTexture(this.texturePath, 24, 24, 0, 0, false);
            this.topTexture = this.prepareTexture(this.texturePath, 10, 10, 0, 0, false);
            this.setTextureMaterial(this.backMat, this.backTexture, 0.7);
            this.setTextureMaterial(this.cushionMat, this.topTexture, 0.7);
          }
          break;
      }
    },
    /**
     * Handles different finish cases based on the selected finish option.
     * @param {string} val - The value representing the selected finish option.
     * @returns {void}
     */
    handleFinishCases(val) {
      // Switch on the finish option name to apply the appropriate texture handling logic
      switch (this.finishOptionName) {
        case FINISH_OPTIONS.TWO_TONE:
        case FINISH_OPTIONS.TWO_TONE_DOOR_ONLY:
        case FINISH_OPTIONS.TWO_TONE_TOP_ONLY:
        case FINISH_OPTIONS.TWO_TONE_TOP_DOOR:
          // Specific handling for dining case (to be implemented)
          break;

        case FINISH_OPTIONS.SOLID_PAINT:
        case FINISH_OPTIONS.SOLID_PAINT_BASE: // Copper top table special case
          // Handling special cases in paint finishes
          // Remove unnecessary suffixes from the value
          val = val.replace(/- Paint Rub Thru| PRT|- Savannah Glaze & Paint Rub Thru| SAV_PRT/g, '');
          // Set the texture path based on the value and load textures for chair, rail, and seat
          this.texturePath = `${TEXTURE_PATHS.PAINT}/${val}.jpg`;
          this.chairTexture = this.loadAndSetTexture(this.texturePath);
          this.railTexture = this.loadAndSetTexture(this.texturePath);
          this.seatTexture = this.loadAndSetTexture(this.texturePath);
          break;

        case FINISH_OPTIONS.SOLID_STAIN:
        case FINISH_OPTIONS.SOLID_STAIN_BASE: // Copper top table special case
          // Handling special cases in stain finishes
          // Remove unnecessary suffixes from the value
          val = val.replace(/- Burnishing| BUR/g, '');
          // Set the texture path based on the species name and value
          this.texturePath = `${TEXTURE_PATHS.DEFAULT}/${this.speciesName}/${val}_${this.speciesName}.jpg`;

          // Handling Elm/Maple special cases
          this.handleElmMapleCases(val);
          break;

        default:
          break;
      }
    },
    /**
     * Handle special cases for Elm species in combination with Maple.
     * @param {string} val - The value representing the selected finish option.
     * @returns {void}
     */
    handleElmMapleCases(val) {
      // Checking if the species name contains 'Elm'
      if (this.speciesName.indexOf('Elm') !== -1) {
        const mapleTexture = `${TEXTURE_PATHS.MAPLE}/${val}_Maple.jpg`;
        this.texturePath = `${TEXTURE_PATHS.ELM}/${val}_Elm.jpg`;

        this.chairTexture = this.loadAndSetTexture(mapleTexture);
        this.railTexture = this.loadAndSetTexture(mapleTexture);

        // Apply specific textures based on the Elm species name
        switch (this.speciesName) {
          case 'Elm_Maple':
          case 'Elm Seat & Maple Frame':
            this.seatTexture = this.loadAndSetTexture(this.texturePath);
            break;
          case 'Elm Seat_Top Rail & Maple Frame':
            this.railTexture = this.loadAndSetTexture(this.texturePath);
            this.seatTexture = this.loadAndSetTexture(this.texturePath);
            break;
          case 'Elm Top Rail Only w_ Maple Frame':
            this.railTexture = this.loadAndSetTexture(this.texturePath);
            this.seatTexture = this.loadAndSetTexture(mapleTexture);
            break;
          case 'Elm (DOORS ONLY)_Maple':
            this.caseTexture = this.loadAndSetTexture(mapleTexture);
            break;
        }
      } else {
        // For non-Elm species, load the texture for chair, rail, and seat from the general texture path
        this.texturePath = `${TEXTURE_PATHS.DEFAULT}/${this.speciesName}/${val}_${this.speciesName}.jpg`;
        this.chairTexture = this.loadAndSetTexture(this.texturePath);
        this.railTexture = this.loadAndSetTexture(this.texturePath);
        this.seatTexture = this.loadAndSetTexture(this.texturePath);
      }
    },
    /**
     * Set texture materials for different parts based on the selected finish and species.
     * @param {string} val - The value representing the selected finish option.
     * @param {boolean} isElmDoorsSpecies - Whether the species is Elm and used for doors.
     * @param {number} roughness - Roughness value to apply to materials.
     * @returns {void}
     */
    setFinishMaterials(val, isElmDoorsSpecies, roughness) {
      // Setting up textures for different parts
      this.backTexture = this.loadAndSetTexture(this.texturePath);
      this.baseTexture = this.loadAndSetTexture(this.texturePath);
      this.bedroomTexture = this.loadAndSetTexture(this.texturePath);
      this.caseTexture = this.loadAndSetTexture(this.texturePath);
      this.leafTexture = this.loadAndSetTexture(this.texturePath);
      this.topTexture = this.loadAndSetTexture(this.texturePath);

      // Rotating seat texture for non-bench products
      if (this.productLine !== PRODUCT_LINES.BENCHES) {
        this.seatTexture.rotation = THREE.MathUtils.degToRad(90);
      }

      // Setting properties of materials for different parts
      this.setTextureMaterial(this.apronMat, this.baseTexture, 0.1);
      this.setTextureMaterial(this.baseMat, this.baseTexture, 0.1);
      this.setTextureMaterial(this.caseMat, this.caseTexture, 0.55);
      this.setTextureMaterial(this.doorMat, isElmDoorsSpecies ? this.baseTexture : this.caseTexture, 0.1);
      this.setTextureMaterial(this.drawerMat, this.caseTexture, 0.1);
      this.setTextureMaterial(this.frameMat, this.bedroomTexture, 0.1);
      this.setTextureMaterial(this.leafMat, this.leafTexture, 0.1);
      this.setTextureMaterial(this.leafApronMat, this.baseTexture, 0.1);
      this.setTextureMaterial(this.insideMat, this.baseTexture, 0.1);
      // Ignore MP2P and MP2U pedestals
      if (!this.pedPath.includes('MP2')) {
        this.setTextureMaterial(this.pedMat, this.baseTexture, 0.1);
      }
      this.setTextureMaterial(this.topMat, this.caseTexture, 0.1);

      // chair wood back
      if (!this.isUpholsteryBack) {
        this.setTextureMaterial(this.backMat, this.backTexture, 0.1);
      }

      // Setting the material for the arch texture or back texture
      if (this.archMat) {
        this.archTexture = this.loadAndSetTexture(`${TEXTURE_PATHS.BIRCH_ARCH}/100 Natural_Birch_Arch.jpg`);
        const isBirchMat = this.speciesName.includes('Map') && val.includes('100 Natural');
        this.setTextureMaterial(this.archMat, isBirchMat ? this.archTexture : this.backTexture, 0.1);
      }

      // Setting the inlay texture
      if (this.inlayMat) {
        console.log("Walnut_Inlay");
        const walnut = `${TEXTURE_PATHS.WALNUT_INLAY}/${val}_Walnut_Inlay.jpg`;
        this.inlayTexture = this.prepareTexture(walnut, 1, 1, 0, 0, true, 90);
        this.setTextureMaterial(this.inlayMat, this.inlayTexture, 0.1);
      }

      // chair
      switch (this.selectUpholstery) {
        case UPHOLSTERY_OPTIONS.WOOD_SEAT:
          this.setTextureMaterial(this.chairMat, this.chairTexture, roughness);
          this.setTextureMaterial(this.railMat, this.railTexture, 0.1);
          this.setTextureMaterial(this.seatMat, this.seatTexture, roughness);
          break;
        case UPHOLSTERY_OPTIONS.WOOD_SEAT_FABRIC_BACK:
        case UPHOLSTERY_OPTIONS.WOOD_SEAT_LEATHER_BACK:
          this.setTextureMaterial(this.chairMat, this.chairTexture, roughness);
          this.setTextureMaterial(this.seatMat, this.seatTexture, roughness);
          break;
        case UPHOLSTERY_OPTIONS.FABRIC_SEAT_BACK:
        case UPHOLSTERY_OPTIONS.LEATHER_SEAT_BACK:
          this.setTextureMaterial(this.chairMat, this.chairTexture, roughness);
          if (this.productLine === PRODUCT_LINES.BARSTOOL) {
            this.setTextureMaterial(this.seatMat, this.seatTexture, roughness);
          }
          break;
        case UPHOLSTERY_OPTIONS.FABRIC_SEAT:
        case UPHOLSTERY_OPTIONS.LEATHER_SEAT:
          this.setTextureMaterial(this.backMat, this.backTexture, roughness);
          this.setTextureMaterial(this.chairMat, this.chairTexture, roughness);
          this.setTextureMaterial(this.railMat, this.railTexture, 0.1);
          if (this.productLine === PRODUCT_LINES.BARSTOOL) {
            this.setTextureMaterial(this.seatMat, this.seatTexture, roughness);
          }
          break;
        default:
          this.setTextureMaterial(this.seatMat, this.seatTexture, roughness);
          break;
      }
    },
    /**
     * Extracts the specific "Two Tone" string pattern from the input.
     *
     * @param {string} input - The string from which to extract the pattern.
     * @returns {string|null} - The extracted content inside the parentheses or null if not found.
     */
    extract2TTString(input) {
      const pattern = /(?:2TT- )?Two Tone \((.*?)\)/;
      const matches = input.match(pattern);
      if (matches && matches[1]) {
        return matches[1].replace(" Only", "");
      }
      return null;
    },
    /**
     * Set materials for the first finish option.
     */
    setFirstFinishMaterials() {
      this.caseTexture = this.loadAndSetTexture(this.texturePath);
      // this.seatTexture = this.loadAndSetTexture(this.texturePath);
      this.topTexture = this.loadAndSetTexture(this.texturePath);

      if (this.productLine !== PRODUCT_LINES.BENCHES) {
        this.seatTexture.rotation = THREE.MathUtils.degToRad(90);
      }

      const finishOption = this.extract2TTString(this.finishOptionName);

      console.log("First Finish", this.finishOptionName);

      if (finishOption) {
        // Apply materials based on the specific option
        switch (finishOption) {
          case FINISH_OPTIONS.DOORS_ONLY:
            // dining case
            this.setTextureMaterial(this.doorMat, this.caseTexture, .1);
            // 2TT- Two Tone (Doors Only) for hutch drawer material
            if (this.drawerMat && this.caseMake === 'H') {
              this.setTextureMaterial(this.drawerMat, this.caseTexture, .1);
            }
            break;
          case FINISH_OPTIONS.DOORS_AND_DRAWERS_ONLY:
            // dining case
            this.setTextureMaterial(this.doorMat, this.caseTexture, .1);
            this.setTextureMaterial(this.drawerMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.DRAWERS_ONLY:
            // dining case
            this.setTextureMaterial(this.drawerMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.FLOATING_PANEL_ONLY:
            // tappan bedroom floating panel
            this.setTextureMaterial(this.backMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.FLOATING_PANEL_DRAWERS:
            // tappan bedroom floating panel
            this.setTextureMaterial(this.backMat, this.caseTexture, .1);
            this.setTextureMaterial(this.drawerMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.HEADBOARD_AND_FOOTBOARD_PANEL:
          this.setTextureMaterial(this.backMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.HEADBOARD_FOOTBOARD_PANEL_AND_DRAWERS:
            // toulon bedroom headboard and footboard panel
            this.setTextureMaterial(this.backMat, this.caseTexture, .1);
            this.setTextureMaterial(this.drawerMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.TOP_ONLY:
          case FINISH_OPTIONS.HEADBOARD_PANEL_ONLY:
            // dining case
            this.setTextureMaterial(this.topMat, this.topTexture, .1);
            this.setTextureMaterial(this.backMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.TOP_AND_DRAWERS:
            // dining case
            this.setTextureMaterial(this.topMat, this.caseTexture, .1);
            this.setTextureMaterial(this.drawerMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.TOP_AND_DOORS:
            // dining case
            this.setTextureMaterial(this.topMat, this.caseTexture, .1);
            this.setTextureMaterial(this.doorMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.TOP_DOORS_AND_DRAWERS:
            // dining case
            this.setTextureMaterial(this.topMat, this.caseTexture, .1);
            this.setTextureMaterial(this.doorMat, this.caseTexture, .1);
            this.setTextureMaterial(this.drawerMat, this.caseTexture, .1);
            break;
          case FINISH_OPTIONS.SEAT_AND_TOP_RAIL:
            this.setTextureMaterial(this.seatMat, this.seatTexture, .1);
            this.setTextureMaterial(this.railMat, this.railTexture, .1);
            break;
          case FINISH_OPTIONS.TOP_RAIL_ONLY:
            this.setTextureMaterial(this.railMat, this.railTexture, .1);
            break;
          case FINISH_OPTIONS.SEAT_ONLY:
            this.setTextureMaterial(this.seatMat, this.seatTexture, .1);
            break;
        }
      } else {
        this.setTextureMaterial(this.leafMat, this.topTexture, .1);
        this.setTextureMaterial(this.seatMat, this.seatTexture, .1);
        this.setTextureMaterial(this.topMat, this.topTexture, .1);
      }
    },
    /**
     * Set materials for the second finish option.
     * @param {string} val - The selected finish option.
     */
    setSecondFinishMaterials(val) {
      this.backTexture = this.loadAndSetTexture(this.texturePath);
      this.baseTexture = this.loadAndSetTexture(this.texturePath);
      this.insideTexture = this.loadAndSetTexture(this.texturePath);

      console.log('Second Finish ', val);

      if (this.archMat) {
        this.archTexture = this.loadAndSetTexture(`${TEXTURE_PATHS.BIRCH_ARCH}/100 Natural_Birch_Arch.jpg`);
        const isBirchMat = this.speciesName.includes('Map') && val.includes('100 Natural');
        this.setTextureMaterial(this.archMat, isBirchMat ? this.archTexture : this.backTexture, 0.1);
      }

      switch (this.finishOptionName) {
        case FINISH_OPTIONS.SOLID_PAINT_BASE:
        case FINISH_OPTIONS.SOLID_PAINT_BASE_C:
          this.setTextureMaterial(this.apronMat, this.baseTexture, .1);
          this.setTextureMaterial(this.baseMat, this.baseTexture, .1);
          this.setTextureMaterial(this.leafMat, this.leafTexture, .1);
          this.setTextureMaterial(this.leafApronMat, this.baseTexture, .1);
          this.setTextureMaterial(this.topMat, this.caseTexture, .1);

          // Ignore MP2P and MP2U pedestals
          if (!this.pedPath.includes('MP2')) {
            this.setTextureMaterial(this.pedMat, this.baseTexture, .1);
          }

          if (!this.isUpholsteryBack) {
            this.setTextureMaterial(this.backMat, this.backTexture, .1);
          }

          break;
        case FINISH_OPTIONS.SOLID_STAIN_BASE:
        case FINISH_OPTIONS.SOLID_STAIN_BASE_C:
          this.setTextureMaterial(this.apronMat, this.baseTexture, .1);
          this.setTextureMaterial(this.baseMat, this.baseTexture, .1);
          this.setTextureMaterial(this.leafMat, this.leafTexture, .1);
          this.setTextureMaterial(this.leafApronMat, this.baseTexture, .1);
          this.setTextureMaterial(this.topMat, this.caseTexture, .1);

          // Ignore MP2P and MP2U pedestals
          if (!this.pedPath.includes('MP2')) {
            this.setTextureMaterial(this.pedMat, this.baseTexture, .1);
          }

          if (!this.isUpholsteryBack) {
            this.setTextureMaterial(this.backMat, this.backTexture, .1);
          }

          break;
        default:
          // Check if the finish option is a specific two-tone option
          const finishOption = this.extract2TTString(this.finishOptionName);
          if (finishOption) {
            const isElmTop = this.speciesName === 'Elm Top_Maple';
            const isElmTopDoorsDrawers = ['Elm (Top, Doors, & Drawers)_Maple', 'Elm (Top, Doors & Drawers)_Maple'].includes(this.speciesName);

            console.log(finishOption);
            // Apply materials based on the specific option
            switch (finishOption) {
              case FINISH_OPTIONS.DOORS_ONLY:
                // dining case
                this.setTextureMaterial(this.caseMat, this.caseTexture, .1);
                this.setTextureMaterial(this.topMat, this.caseTexture, .1);
                // 2TT- Two Tone (Doors Only) for hutch drawer material
                if (this.drawerMat && this.caseMake === 'H') {
                  this.setTextureMaterial(this.drawerMat, this.caseTexture, .1);
                }
                break;
              case FINISH_OPTIONS.DOORS_AND_DRAWERS_ONLY:
                // dining case
                this.setTextureMaterial(this.caseMat, this.caseTexture, .55);
                this.setTextureMaterial(this.topMat, this.caseTexture, .1);
                break;
              case FINISH_OPTIONS.DRAWERS_ONLY:
                // dining case
                this.setTextureMaterial(this.caseMat, this.caseTexture, .55);
                this.setTextureMaterial(this.doorMat, (isElmTop || isElmTopDoorsDrawers) ? this.caseTexture : this.baseTexture, .1);
                this.setTextureMaterial(this.topMat, this.caseTexture, .1);
                this.setTextureMaterial(this.baseMat, this.baseTexture, .1);
                break;
              case FINISH_OPTIONS.FLOATING_PANEL_ONLY:
                this.setTextureMaterial(this.frameMat, this.baseTexture, .1);
                this.setTextureMaterial(this.drawerMat, (isElmTop || isElmTopDoorsDrawers) ? this.caseTexture : this.baseTexture, .1);
                break;
              case FINISH_OPTIONS.FLOATING_PANEL_DRAWERS:
                this.setTextureMaterial(this.frameMat, this.baseTexture, .1);
                break;
              case FINISH_OPTIONS.HEADBOARD_AND_FOOTBOARD_PANEL:
                this.setTextureMaterial(this.frameMat, this.baseTexture, .1);
                this.setTextureMaterial(this.drawerMat, (isElmTop || isElmTopDoorsDrawers) ? this.caseTexture : this.baseTexture, .1);
                break;
              case FINISH_OPTIONS.HEADBOARD_FOOTBOARD_PANEL_AND_DRAWERS:
                this.setTextureMaterial(this.frameMat, this.baseTexture, .1);
                break;
              case FINISH_OPTIONS.TOP_ONLY:
              case FINISH_OPTIONS.HEADBOARD_PANEL_ONLY:
                // dining case
                this.setTextureMaterial(this.caseMat, this.caseTexture, .55);
                this.setTextureMaterial(this.doorMat, (isElmTop || isElmTopDoorsDrawers) ? this.caseTexture : this.baseTexture, .1);
                this.setTextureMaterial(this.drawerMat, (isElmTop || isElmTopDoorsDrawers) ? this.caseTexture : this.baseTexture, .1);
                this.setTextureMaterial(this.baseMat, this.baseTexture, .1);
                this.setTextureMaterial(this.frameMat, this.baseTexture, .1);
                break;
              case FINISH_OPTIONS.TOP_AND_DOORS:
                // dining case
                this.setTextureMaterial(this.caseMat, this.caseTexture, .55);
                this.setTextureMaterial(this.drawerMat, (isElmTop || isElmTopDoorsDrawers) ? this.caseTexture : this.baseTexture, .1);
                break;
              case FINISH_OPTIONS.TOP_AND_DRAWERS:
                // dining case
                this.setTextureMaterial(this.caseMat, this.caseTexture, .1);
                this.setTextureMaterial(this.doorMat, this.caseTexture, .1);
                this.setTextureMaterial(this.baseMat, this.baseTexture, .1);
                break;
              case FINISH_OPTIONS.TOP_DOORS_AND_DRAWERS:
              case FINISH_OPTIONS.TOP_DOORS_AND_DRAWERS_C:
                // dining case
                this.setTextureMaterial(this.caseMat, this.caseTexture, .55);
                break;
              case FINISH_OPTIONS.SEAT_AND_TOP_RAIL:
                this.setTextureMaterial(this.chairMat, this.chairTexture, .1);
                break;
              case FINISH_OPTIONS.TOP_RAIL_ONLY:
                this.setTextureMaterial(this.chairMat, this.chairTexture, .1);
                const isWoodSeat = (this.productLine === PRODUCT_LINES.CHAIR && !this.CUSHION.visible) ||
                  (this.productLine === PRODUCT_LINES.BARSTOOL && this.CUSHION.visible);
                if (isWoodSeat) {
                  this.setTextureMaterial(this.seatMat, this.seatTexture, .1);
                }
                break;
              case FINISH_OPTIONS.SEAT_ONLY:
                this.setTextureMaterial(this.chairMat, this.chairTexture, .1);
                this.setTextureMaterial(this.railMat, this.railTexture, .1);
                break;
              case FINISH_OPTIONS.INSIDE_ONLY:
                this.setTextureMaterial(this.insideMat, this.insideTexture, .1);
                break;
              case FINISH_OPTIONS.BODY_ONLY:
                this.setTextureMaterial(this.caseMat, this.caseTexture, .1);
                break;
            }

            break;
          } else {
            this.setTextureMaterial(this.chairMat, this.chairTexture, .1);
            this.setTextureMaterial(this.apronMat, this.baseTexture, .1);
            this.setTextureMaterial(this.baseMat, this.baseTexture, .1);
            this.setTextureMaterial(this.leafApronMat, this.baseTexture, .1);

            // Ignore MP2P and MP2U pedestals
            if (!this.pedPath.includes('MP2')) {
              this.setTextureMaterial(this.pedMat, this.baseTexture, .1);
            }

            // chair wood back
            if (!this.isUpholsteryBack) {
              this.setTextureMaterial(this.backMat, this.backTexture, .1);
            }

            // Setting the inlay texture
            if (this.inlayMat && this.isWoodStain(val)) {
              console.log("Walnut_Inlay");
              const walnut = `${TEXTURE_PATHS.WALNUT_INLAY}/${val}_Walnut_Inlay.jpg`;
              this.inlayTexture = this.prepareTexture(walnut, 1, 1, 0, 0, true, 90);
              this.setTextureMaterial(this.inlayMat, this.inlayTexture, 0.1);
            }

            if (this.baseDF === 'AUSC28') {
              this.setTextureMaterial(this.topMat, this.baseTexture, 0.1);
              this.setTextureMaterial(this.caseMat, this.baseTexture, 0.1);
              this.setTextureMaterial(this.doorMat, this.baseTexture, 0.1);
              this.setTextureMaterial(this.insideMat, this.baseTexture, 0.1);
            }
          }
      }
    },
    /**
     *  Start automated rotate function if autoRotate is true
     */
    animate() {
      if (!this.controls.autoRotate) {
        window.cancelAnimationFrame(this.animateId); // Stop the animation
        this.animateId = undefined;
      }
      // required if controls.enableDamping or controls.autoRotate are set to true
      this.animateId = requestAnimationFrame(this.animate);
      this.controls.update();
      this.render();
    },
    /**
     * Load and render the initial 3D scene and camera by using the default settings
     */
    init() {
      try {
        const clientWidth = document.getElementsByClassName('dp-collection-picker')[0].clientWidth;
        const clientHeight = document.getElementsByClassName('dp-options-picker')[0].clientHeight;

        if (clientWidth && clientWidth > 300) {
          this.width = clientWidth;
        }
        if (clientHeight && clientHeight > 300) {
          this.height = clientHeight;
        }

        this.camera = new THREE.PerspectiveCamera(
          30,
          this.width / this.height,
          0.25,
          200,
        );
        this.scene = new THREE.Scene();
        this.textureLoader = new THREE.TextureLoader();
        this.http = new XMLHttpRequest();
        this.initialSetupScene();
        this.initialLoadingModel();
      } catch (e) {
        console.warn(`ModelRender | init | ${e}`);
      }
    },
    /**
     * Setup and config the initial scene and control of three.js canvas element.
     */
    initialSetupScene() {
      try {
        // Load environment texture
        this.textureLoader.load('/models/models/bg.jpg', (texture) => {
          const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          this.scene.background = new THREE.Color(0xeeeeee);
          this.scene.environment = envMap;

          texture.dispose();
          pmremGenerator.dispose();
        });

        // Set up the renderer
        this.setupRenderer();

        // Set up controls for the scene
        this.setupControls();

      } catch (e) {
        console.warn(`ModelRender | initialSetupScene | ${e}`);
      }
    },
    // Configure the renderer settings
    setupRenderer() {
      this.container = document.getElementById("canvas");
      this.renderer = new THREE.WebGLRenderer({canvas: this.container, antialias: true});

      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.width, this.height);
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;
      this.renderer.outputColorSpace = THREE.SRGBColorSpace;

      const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      pmremGenerator.compileEquirectangularShader();
    },
    // Configure the controls for the scene
    setupControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.controls.addEventListener('change', this.render);
      this.controls.minDistance = 2;
      this.controls.target.set(0, -1, 1);
      this.controls.autoRotate = false;
      this.controls.autoRotateSpeed = 2;
      this.controls.enableDamping = true;
      this.controls.enableZoom = true;
      this.controls.zoomSpeed = 1.2;
      this.controls.minPolarAngle = Math.PI * 2 / 5;
      this.controls.maxPolarAngle = Math.PI * 2 / 5;

      this.controls.update();
    },
    /**
     * Load the initial models on 3D scene.
     */
    async initialLoadingModel() {
      this.leafPath = '';
      this.legPath = '';
      this.modelPath = '';
      this.pedPath = '';
      this.texturePath = '';
      this.leg = '01'
      this.leaf = '000';
      this.ped = '';

      try {
        const currentModel = this.initialModels.find(model => model.BASE_ID === this.baseId);
        if (!currentModel) return;
        this.setInitialModelAttributes(currentModel);
        if (this.productLine === PRODUCT_LINES.TABLE) {
          await this.loadTableModels();
        } else {
          await this.loadOtherProductModels();
        }
      } catch (e) {
        console.warn(`Exists any model problem at initial loading for product -
        ${this.productLine} and df - ${this.baseDF} | ${e}`)
      }
    },
    /**
     * Set initial attributes for the model based on the provided currentModel.
     * @param {Object} currentModel - The model data to set initial attributes from.
     */
    setInitialModelAttributes(currentModel) {
      // Set default values for model attributes

      console.log("setInitialModelAttributes ",this.df);

      this.baseDF = this.df;
      this.arms = '02';
      this.edge = 'EA';
      this.leaf = '000';
      this.shape = 'A';
      this.base = 'P1';
      this.leg = '01';
      this.INITIAL_HARDWARE = [];
      this.NATURALE = null;
      this.TOP = {};
      this.TOPCLONE = {};
      // Set the initial model from the provided currentModel
      this.INITIAL_MODEL = currentModel.INITIAL_MODEL;
      // If the current model has initial hardware data, parse and set it
      if (currentModel.INITIAL_HARDWARE) {
        this.INITIAL_HARDWARE = JSON.parse(currentModel.INITIAL_HARDWARE);
      }
      if (this.baseId === 'PLANK TABLE' || this.isSpecificTableTopMaterial) {
        this.edge = 'EE';
      }
      if (this.isSpecificTableTopMaterial) {
        this.shape = 'B';
      }
    },
    async loadTableModels() {
      if (!this.isSpecificTable) {
        this.leafPath = `/models/${this.productLine}/${this.baseDF.substring(0, 2)}.glb`;
        console.log("poziv 1fff ", this.productLine);
        await this.loadModel(this.leafPath);
      }
      this.modelPath = `/models/${this.productLine}/${this.INITIAL_MODEL}.glb`;
      console.log("poziv 1");
      await this.loadModel(this.modelPath);

      if (!['BOULDER TABLE', 'SYDNEY STORAGE TABLE'].includes(this.baseId)) {
        this.ped = this.INITIAL_HARDWARE['BASE'];
        if (this.ped && ['M', 'P'].includes(this.ped.substring(0, 1))) {
          this.pedPath = `/models/${this.productLine}/${this.ped}.glb`;
          console.log("poziv 1");
          await this.loadModel(this.pedPath);
        } else {
          this.legPath = `/models/${this.productLine}/Legs.glb`;
          console.log("poziv 1");
          await this.loadModel(this.legPath);
        }
      }
      await this.updateRenderSettings();
    },
    async loadOtherProductModels() {
      this.modelPath = `/models/${this.productLine}/${this.INITIAL_MODEL}.glb`;
      console.log("poziv 1 ", this.productLine, this.INITIAL_MODEL);
      await this.loadModel(this.modelPath);
      if (this.productLine === PRODUCT_LINES.OCCASIONAL) {
        await this.updateRenderSettings();
      }
    },
    /**
     * @param {string} name - swatch name.
     * @return {boolean}
     */
    isWoodStain(name) {
      return /^\d+$/.test(name.substring(0, 3));
    },
    /**
     * Load a GLTF model using the GLTFLoader.
     * @param {string} path - The path to the GLTF (.glb) file.
     */
    loadModel(path) {
      this.cushionMat = null;
      this.seatMat = null;
      this.CUSHION = null;
      this.SEAT = null;


      console.log("PATH ", path);

      // @ts-ignore
      const loader = new GLTFLoader();
      // @ts-ignore
      loader.load(
        path,
        (gltf) => {
          // called when the resource is loaded
          this.model = gltf.scene;

          try {
            this.model.traverse((child) => {
              this.loadTableBaseTopModels(child);

              console.log("CHILD ", child.name);

              // load dining case hardware
              if (this.productLine === PRODUCT_LINES.DINING_CASES && child.name.includes('DOOR_')) {
                console.log("KNOB ");

                const knob = child.name.substring(5, child.name.length);
                this.HARDWARE['DOOR'][knob] = child;
                this.HARDWARE['DOOR'][knob].visible = false;
              }
              if ([PRODUCT_LINES.DINING_CASES, PRODUCT_LINES.OCCASIONAL, PRODUCT_LINES.HOME_OFFICE].includes(this.productLine) && child.name.includes('DRAWER_')) {
                console.log("KNOB ");

                const knob = child.name.substring(7, child.name.length);
                this.HARDWARE['DRAWER'][knob] = child;
                this.HARDWARE['DRAWER'][knob].visible = false;
              }
              if (this.productLine === PRODUCT_LINES.DINING_CASES && child.name.includes('MIDDLE_')) {
                console.log("KNOB ");

                const knob = child.name.substring(7, child.name.length);
                this.HARDWARE['MIDDLE'][knob] = child;
                this.HARDWARE['MIDDLE'][knob].visible = false;
              }
              if (this.productLine === PRODUCT_LINES.DINING_CASES && (child.name.includes('TOP_'))) {
                console.log("KNOB ");

                const knob = child.name.substring(4, child.name.length);
                this.HARDWARE['TOP'][knob] = child;
                this.HARDWARE['TOP'][knob].visible = false;
              }
              if (this.productLine === PRODUCT_LINES.OCCASIONAL && child.name.includes('HARDWARE')) {
                console.log("KNOB 222");

                this.HARDWARE['DRAWER']['knob'] = child;
                this.HARDWARE['DRAWER']['knob'].visible = true;
              }

              switch (child.name) {
                case 'ARMS':
                  this.ARMS = child;
                  this.ARMS.visible = this.arms === '02' || this.arms === '12';
                  break;
                case 'Inlay':
                  this.BACK[child.name] = child;
                  this.BACK['Inlay'].visible = true;
                  break
                case 'NoInlay':
                  this.BACK[child.name] = child;
                  this.BACK['NoInlay'].visible = false;
                  break;
                case 'CASE':
                  this.CASE = child;
                  break;
                case 'COPPER': // Copper Top table
                case 'NATURALE': // Live Edge, Naturale table
                case 'ZINC': // Zinc Top table
                  this.NATURALE = child;
                  this.topHeight = this.NATURALE.position.y;
                  this.topBoundingMax = child.geometry.boundingBox.max;
                  this.topBoundingMin = child.geometry.boundingBox.min;
                  break;
                case 'CORNER':
                  this.CORNER = child;
                  break;
                case 'CUSHION':
                  this.CUSHION = child;
                  break;
                case 'NAILHEADS':
                  this.NAILHEADS = child;
                  break;
                case 'SEAT':
                  this.SEAT = child;
                  break;
                case `BASE_${this.leg}`:
                  for (let i = 0; i < 4; i++) {
                    this.LEG[i][this.leg].visible = (this.leg && !this.ped);
                  }
                  break;
                case `LEAF_${this.leaf}_${this.edge}`:
                  if (this.LEAVE[this.leaf][this.edge]) {
                    this.LEAVE[this.leaf][this.edge].visible = true;
                    this.LEAVE[this.leaf]['apron'].visible = true;
                  }
                  break;
                case `${this.base}${this.tableHeight}`:
                  if (this.PED[`${this.base}${this.tableHeight}`]) {
                    this.PED[`${this.base}${this.tableHeight}`].visible = true;
                  }
                  break;
                case `TOP_${this.edge}`:
                  if (this.TOP[this.edge]) {
                    this.TOP[this.edge].visible = true;
                  }
                  if (this.TOPCLONE[this.edge]) {
                    this.TOPCLONE[this.edge].visible = true;
                  }
                  break;
                case `DOOR_${this.INITIAL_HARDWARE['DOOR']}`:
                console.log("KNOB ");

                  this.HARDWARE['DOOR'][this.INITIAL_HARDWARE['DOOR']].visible = true;
                  break;
                case `DRAWER_${this.INITIAL_HARDWARE['DRAWER']}`:
                console.log("KNOB ");

                  this.HARDWARE['DRAWER'][this.INITIAL_HARDWARE['DRAWER']].visible = true;
                  break;
                case `TOP_${this.INITIAL_HARDWARE['TOP']}`:
                console.log("KNOB ");

                  this.HARDWARE['TOP'][this.INITIAL_HARDWARE['TOP']].visible = true;
                  break;
                case `MIDDLE_${this.INITIAL_HARDWARE['MIDDLE']}`:
                console.log("KNOB ");

                  this.HARDWARE['MIDDLE'][this.INITIAL_HARDWARE['MIDDLE']].visible = true;
                  break;
                default:
                  break;
              }

              if (child.isMesh) {
                switch (child.material.name) {
                  case 'shadow':
                    child.material.transparent = true;
                    child.material.opacity = 0.3;
                    this.SHADOW = child;
                    break;
                  case 'seat':
                    if (child.name === 'SEAT') {
                      this.seatMat = child.material;
                    }
                    if (child.name === 'CUSHION') {
                      this.cushionMat = child.material;
                    }
                    break;
                  case 'cushion':
                    this.cushionMat = child.material;
                    break;
                  case 'Arch':
                    this.archMat = child.material;
                    break;
                  case 'Chair':
                    this.chairMat = child.material;
                    break;
                  case 'corner':
                    this.cornerMat = child.material;
                    break;
                  case 'knob':
                  console.log("KNOB 333");

                    this.knobMat = child.material;
                    break;
                  case 'back':
                  case 'Back':
                    this.backMat = child.material;
                    if (this.productLine !== 'bedroom') {
                      this.isBackExist = true;
                    }
                    break;
                  case 'heads':
                  case 'Heads':
                    this.headsMat = child.material;
                    break;
                  case 'Case':
                    this.caseMat = child.material;
                    break;
                  case 'Door':
                    this.doorMat = child.material;
                    break;
                  case 'Drawer':
                  console.log("KNOB 111");

                    this.drawerMat = child.material;
                    break;
                  case 'Inlay':
                    this.inlayMat = child.material;
                    break;
                  case 'Leaf':
                    this.leafMat = child.material;
                    break;
                  case 'Ped':
                    this.pedMat = child.material;
                    break;
                  case 'Rail':
                    this.railMat = child.material;
                    break;
                  case 'Top':
                    this.topMat = child.material;
                    break;
                  case 'Base':
                    this.baseMat = child.material;
                    break;
                  case 'Frame':
                    this.frameMat = child.material;
                    break;
                  case 'Apron':
                    if (child.name.substring(0, 5) === 'APRON') {
                      this.leafApronMat = child.material;
                    } else {
                      this.apronMat = child.material;
                      // this.leafApronMat = child.material;
                    }
                    break;
                  case 'Inside': // Austin case
                    console.log('INSIDEEE');
                    this.insideMat = child.material;
                    break;
                  default:
                    break;
                }
              }
            });

            if (this.SEAT && this.CUSHION) {
              const customBarstools = [
                'STELLA', 'BRINKLEY', 'VENICE', 'CLEVELAND', 'HARTFORD', 'ADAMS', 'PRESTIGE', 'BAKERSFIELD', 'ASPEN','KIRBY','FRESNO','BELLA','BACKLESS BARSTOOL','HANNAH'
              ]
              if (customBarstools.includes(this.baseId.substring(4))) {
                this.CUSHION.visible = false;
              }
            }

            if (this.productLine !== PRODUCT_LINES.TABLE) {
              this.scene.remove(this.prevModel);
              this.scene.add(this.SHADOW);
              this.scene.add(this.model);
              this.prevModel = this.model;
              this.controls.focus(this.prevModel);
              this.prevModel.add(this.SHADOW);
              if (!this.cameraDefault
                || (this.cameraDefault.x === 0 && this.cameraDefault.y === 0 && this.cameraDefault.z === 0)) {
                this.cameraDefault.x = this.camera.position.x;
                this.cameraDefault.y = this.camera.position.y;
                this.cameraDefault.z = this.camera.position.z;
              }
            } else {
              this.scene.add(this.model);
            }

            this.animate(); // rotate the current scene automatically
            if (this.productLine === PRODUCT_LINES.TABLE) {
              if (this.apronMat) {
                this.apronMat.map = this.baseTexture;
                this.apronMat.roughness = .1;
              }
              if (this.leafApronMat) {
                this.leafApronMat.map = this.baseTexture;
                this.leafApronMat.roughness = .1;
              }
              if (this.baseMat) {
                this.baseMat.map = this.baseTexture;
                this.baseMat.roughness = .1;
              }
              if (this.pedMat) {
                this.pedMat.map = this.baseTexture;
                this.pedMat.roughness = .1;
              }
              if (this.topMat) {
                this.topMat.map = this.topTexture;
                this.topMat.roughness = .1;
              }
              if (this.leafMat) {
                this.leafMat.map = this.topTexture;
                this.leafMat.roughness = .1;
              }
              this.toggleLeaf();

              // Coordinate 4 legs for regular tables.
              if (this.LEG[0].length > 0) {
                this.tableLegPosition();
              }
            }
            this.isModelExists = true;
            this.isLoading = false;
          } catch (e) {
            console.log('Scene traverse error:', e);
            this.isLoading = false;
          }
        },
        (xhr) => {
          // called while loading is progressing
          this.loadingAmount = xhr.loaded;
          this.loadingTotal = xhr.total;
          this.isModelExists = true;
          this.isLoading = true;
        },
        (error) => {
          this.isModelExists = false;
          this.isLoading = false;
          // called when loading has errors
          console.error("An error happened", error);
        }
      );
    },
    /**
     * Load the table-top, leg, apron, leg and pedestal models from the child element.
     * @param {Object} child - The child element of the model.
     */
    loadTableBaseTopModels(child) {
      // load leaf model
      if (child.name.substring(0, 4) === 'LEAF') {
        const leaf = child.name.substring(5, 8);
        const edge = child.name.substring(9, child.name.length);
        this.LEAVE[leaf][edge] = child;
      }
      if (child.name.substring(0, 5) === 'APRON') {
        const leaf = child.name.substring(6, 9);
        this.LEAVE[leaf]['apron'] = child;
      }

      // load table-top model
      if (child.name.substring(0, 3) === 'TOP' && child.isGroup) {
        const edge = child.name.substring(4, child.name.length);
        if (edge) {
          this.TOP[edge] = child;
          this.TOP[edge].visible = false;
          this.topHeight = this.TOP[edge].position.y;
        }

        child.children.forEach(c => {
          if (c.material.name === 'Top') {
            this.topBoundingMax = c.geometry.boundingBox.max;
            this.topBoundingMin = c.geometry.boundingBox.min;
          }
        });

        // except the specific tables like copper, sydney...
        if (parseInt(this.baseId.substring(4, 8))) {
          this.TOP0.set(
            child.position.x,
            child.position.y,
            child.position.z,
          );
          this.TOPCLONE[edge] = child.clone();
          this.TOPCLONE[edge].visible = false;
          this.TOPCLONE[edge].rotation.y = Math.PI;
          if (Object.keys(this.LEAVE['112']).length > 0) {
            Object.keys(this.LEAVE).forEach((leaf) => {
              this.LEAVE[leaf][edge].position.set(
                0, // align center
                this.TOP[edge].position.y,
                this.TOP[edge].position.z
              );
              // this.LEAVE[leaf]['apron'].position.set(
              //   this.LEAVE[leaf]['apron'].geometry.boundingBox.max.x,
              //   this.TOP[edge].position.y,
              //   this.TOP[edge].position.z
              // );
              this.scene.add(this.LEAVE[leaf][edge]);
              this.scene.add(this.LEAVE[leaf]['apron']);
            });
          }

          this.TOPCLONE[edge].position.set(
            -child.position.x - 0.005, // top gap issue
            child.position.y,
            child.position.z
          );
          // this.scene.add(this.TOP[edge]); // safari support
          this.scene.add(this.TOPCLONE[edge]);
        }
      }

      // load leg part
      if (child.name.substring(0, 5) === 'BASE_') {
        const leg = child.name.substring(5, child.name.length);
        this.LEG[0][leg] = child;
        this.LEG[0][leg].visible = false;

        for (let i = 0; i < 4; i++) {
          this.LEG[i][leg] = child.clone();
          this.LEG[i][leg].visible = false;
          this.scene.add(this.LEG[i][leg]);
        }
      }

      // load ped part
      if (!child.name.includes('Plane') && child.name.substring(0, 1) === 'P') {
        this.PED[child.name] = child;
        this.PED[child.name].visible = false;

        if (this.ped === 'PG2' && child.name === 'P100') {
          this.PED_CLONE[child.name] = child.clone();
          this.PED_CLONE[child.name].visible = true;

          let diff;
          if (this.shape === 'L') {
            diff = this.TOP[this.edge].position.x * 0.75;
          } else if (this.shape !== 'L' && !this.isSpecificTable) {
            diff = this.TOP[this.edge].position.x * 0.55;
          }

          if (this.isSpecificTable) {
            diff = child.geometry.boundingBox.max.x * 0.75 + 0.55;
          }

          this.PED_CLONE[child.name].position.set(
            -child.position.x + diff,
            child.position.y,
            child.position.z
          );
          this.PED[child.name].position.set(
            child.position.x - diff,
            child.position.y,
            child.position.z
          );
          this.scene.add(this.PED_CLONE[child.name]);
        }
      }

      // Pedestal floor shadow
      if (this.productLine === PRODUCT_LINES.TABLE && child.isGroup && child.name === 'Scene') {
        child.children.forEach(c => {
          if (c.name.includes('Plane')) {
            this.PLANE[c.name] = c;
            this.PLANE[c.name].visible = c.name === `Plane${this.base.substring(1, 2)}`;
          }
          if (this.ped === 'PG2' && c.name === 'Plane1') {
            this.PLANE[c.name].visible = false;
          }
        })
      }
    },
    /**
     * Real-time detect the window resize activity, and re-render the 3D scene
     */
    onWindowResize() {
      const width = document.getElementsByClassName('dp-collection-picker')[0].clientWidth;
      const height = window.innerHeight - 100;
      if (this.camera) {
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
      }
      if (this.renderer) {
        this.renderer.setSize(width, height);
      }

      this.render();
    },
    /**
     * Render 3D scene and locate camera controls
     */
    render() {
      if (this.renderer) {
        this.renderer.render(this.scene, this.camera);
      }
    },
    /**
     * Remove invisible parts of table assembly
     */
    resetScene() {
      // only left background part, and remove all children
      this.scene.remove(this.model);
      while (this.scene.children.length > 0) {
        this.scene.remove(this.scene.children[0]);
      }
    },
    /**
     * Resets the table configuration based on the provided leaf.
     * @param {string} leaf - The leaf identifier. Default is '000'.
     */
    resetTable(leaf = '000') {
      // Determine if the table needs to be fully reset
      const isFullReset = leaf === '000';

      // Adjust the table's leaf position
      this.moveLeafX = isFullReset ? 0 : this.LEAVE[this.leaf][this.edge].geometry.boundingBox.max.x;

      // Handle the visibility and position of the LEAVE components
      Object.keys(this.LEAVE).forEach((leafKey) => {
        Object.keys(this.LEAVE[leafKey]).forEach((edge) => {
          const isVisible = !isFullReset;
          this.LEAVE[leafKey][edge].visible = isVisible;
          this.LEAVE[leafKey]['apron'].visible = isVisible;
        });
      });

      // Adjust the position of the TOP and TOPCLONE components
      const adjustTopPosition = (component, posX) => {
        Object.keys(component).forEach((edge) => {
          component[edge].position.set(
            posX,
            component[edge].position.y,
            component[edge].position.z
          );
        });
      };
      adjustTopPosition(this.TOP, isFullReset ? this.TOP0.x : this.TOP0.x - this.moveLeafX);
      adjustTopPosition(this.TOPCLONE, isFullReset ? -this.TOP0.x - 0.005 : -this.TOP0.x + this.moveLeafX - 0.01);

      // Adjust the position of the LEG components
      if (Object.keys(this.LEG[0]).length > 0) {
        const posX = this.LEG0.x + this.moveLeafX;
        for (let i = 0; i < 4; i++) {
          Object.keys(this.LEG[i]).forEach((leg) => {
            this.LEG[i][leg].position.set(
              ((i === 0 || i === 3) ? 1 : -1) * posX,
              this.LEG[i][leg].position.y,
              this.LEG[i][leg].position.z
            );
          });
        }
      }

      // Handle the P1G- (2) Single Augusta specific adjustments
      if (this.ped === 'PG2' && this.PED['P100'] && this.PED_CLONE['P100']) {
        const diff = this.shape === 'L' ? this.TOP0.x * 0.75 : this.TOP0.x * 0.55;
        this.PED_CLONE['P100'].position.set(-diff + this.moveLeafX, this.PED_CLONE['P100'].position.y, this.PED_CLONE['P100'].position.z);
        this.PED['P100'].position.set(diff - this.moveLeafX, this.PED['P100'].position.y, this.PED['P100'].position.z);
      }
    },
    /**
     * Action control to reset zoom scale on scene.
     */
    resetZoom() {
      if (this.productLine === PRODUCT_LINES.TABLE) {
        this.updateRenderSettings();
      } else {
        this.scene.remove(this.prevModel);
        this.scene.add(this.SHADOW);
        this.scene.add(this.model);
        this.prevModel = this.model;
        this.controls.focus(this.prevModel);
        this.prevModel.add(this.SHADOW);

        this.camera.position.set(
          this.cameraDefault.x,
          this.cameraDefault.y,
          this.cameraDefault.z,
        );
        this.camera.updateProjectionMatrix();
      }
    },
    /**
     * Toggle the rotate button and action
     */
    rotate() {
      this.isRotate = !this.isRotate;
      this.controls.autoRotate = this.isRotate;
      this.controls.update();
    },
    /**
     * @param {boolean} isSimilarChair - true if it's similar chair chosen.
     * @param {string} option - Arm/Side option.
     * @param {string} description - order description.
     */
    async saveOrderImage(isSimilarChair, option = '', description) {
      // similar chair ARM/SIDE option preparing.
      if (isSimilarChair) {
        this.togglePart(option);
      }

      // re-render the 3D scene before creating order image.

      await this.render();

      if (!this.renderer || !this.renderer.domElement) {
        return;
      }

      await this.renderer.domElement.toBlob((blob) => {
        let data = new FormData();
        data.append('image', blob);

        axios.post('/api/order/image', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((res) => {
            if (res.data.success) {
              const {image_name} = res.data.image;
              const imageUrl = `${window.location.origin}/storage/orders/${image_name}`;

              this.$store.dispatch('addOrderImage', {
                image: imageUrl,
                description,
              });
            }
          });
      }, 'image/jpeg', 0.5);
    },
    /**
     * @param {String} type of action e.g: rotate, zoom, etc.
     * @param {String} action of camera control.
     */
    startAction(type, action) {
      switch (type) {
        case 'rotate':
          const angle = .15;
          this.controls.rotateControl(action, angle);
          break;
        case 'zoom':
          switch (action) {
            case 'in':
              this.controls.dollyIn();
              break;
            case 'out':
              this.controls.dollyOut();
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    /**
     * Clone 4 table legs from one model, and calculate each one's position and rotation.
     */
    tableLegPosition() {
      try {
        const shape = this.shape || 'default';
        const defaultAngle = Math.PI / 2 - this.deflects[shape].r;
        const defaultLeg = this.LEG[0][this.leg];

        // Calculate boundaries
        let boundMaxX = this.topBoundingMax['x'] - this.topBoundingMin['x'];
        const boundMaxY = defaultLeg.position['y'];
        const boundMaxZ = this.topBoundingMax['z'];

        // Calculate boundary differences
        let boundDiffX = this.deflects[shape].x + defaultLeg.geometry.boundingBox.max.x;
        const boundDiffY = this.deflects[shape].y;
        let boundDiffZ = this.deflects[shape].z + defaultLeg.geometry.boundingBox.max.z;

        // Adjustments for specific table types
        if (['6060', '6666', '7272'].includes(this.baseDF) && shape === 'B') {
          boundDiffX = boundDiffX + 0.25; // specific round top table boundary
          boundDiffZ = boundDiffZ + 0.25;
        }
        if (this.baseDF.indexOf('BB') !== -1) {
          boundDiffX += 1.130; // PLANK TABLE with Breadboard Leaves
        }
        if (this.isSpecificTable) {
          boundMaxX = boundMaxX / 2; // Specific Table Tops
          if (this.baseId === 'DROP LEAF TABLE') {
            boundDiffX = boundDiffX + 0.60;
          }
        }
        let coordinates = {
          0: {
            position: {
              x: boundMaxX - boundDiffX,
              y: boundMaxY - boundDiffY,
              z: boundMaxZ - boundDiffZ,
            },
            rotation: defaultAngle,
          },
          1: {
            position: {
              x: -boundMaxX + boundDiffX,
              y: boundMaxY - boundDiffY,
              z: boundMaxZ - boundDiffZ,
            },
            rotation: defaultAngle - Math.PI / 2,
          },
          2: {
            position: {
              x: -boundMaxX + boundDiffX,
              y: boundMaxY - boundDiffY,
              z: -boundMaxZ + boundDiffZ,
            },
            rotation: defaultAngle - Math.PI,
          },
          3: {
            position: {
              x: boundMaxX - boundDiffX,
              y: boundMaxY - boundDiffY,
              z: -boundMaxZ + boundDiffZ,
            },
            rotation: defaultAngle - Math.PI * 3 / 2,
          }
        }

        if (this.LEG0) {
          this.LEG0.set(
            coordinates[0].position.x,
            coordinates[0].position.y,
            coordinates[0].position.z,
          );
        }

        if (Object.keys(this.LEG[0]).length > 0) {
          for (let i = 0; i < 4; i++) {
            Object.keys(this.LEG[i]).forEach(leg => {
              this.LEG[i][leg].position.set(
                coordinates[i].position.x,
                coordinates[i].position.y,
                coordinates[i].position.z,
              );
              this.LEG[i][leg].rotation.y = coordinates[i].rotation;
              // todo: leg floor shadow plane.
              /** if (i === 0) {
               this.LEG[i]['plane'] = this.PLANE['Plane'];
               } else {
               this.LEG[i]['plane'] = this.PLANE['Plane'].clone();
               }

               if (this.LEG[i]['plane']) {
               this.LEG[i]['plane'].visible = true;
               this.LEG[i]['plane'].material.transparent = true;
               this.LEG[i]['plane'].material.opacity = 0.3;
               this.LEG[i]['plane'].position.set(
               coordinates[i].position.x,
               this.LEG[i]['plane'].position.y,
               coordinates[i].position.z,
               );
               this.scene.add(this.LEG[i]['plane']);
               }*/
            });
          }
        }
      } catch (e) {
        console.warn(`Found this problem of legs coordinates and positions for table - ${this.baseDF} | ${e}`);
      }
    },
    /**
     * Calculates the base height based on the dimension factor.
     * @param {String} df - Dimension factor.
     * @param {Boolean} isTop - True if the selected table is a regular top.
     * @returns {number} - Calculated base height.
     */
    calculateBaseHeight(df, isTop) {
      let baseHeight = 0;
      switch (df.charAt(0)) {
        case 'P':
          baseHeight = 1.60 - (isTop ? 0.10 : 0.05);

          if (this.baseId === 'OSLO TABLE') {
            baseHeight = this.topHeight;
          }
          break;
        case 'M':
          baseHeight = 1.85 - (isTop ? 0.10 : 0.05);
          break;
        default:
          baseHeight = this.topHeight;
          break;
      }

      return baseHeight;
    },
    /**
     * Adjusts the top position of various elements based on the provided dimension factor.
     * @param {String} df - Dimension factor to determine the base height.
     */
    async tableTopPosition(df) {
      const baseHeight = this.calculateBaseHeight(df, !!this.TOP);

      if (this.NATURALE) {
        this.NATURALE.position.set(
            this.NATURALE.position.x,
            baseHeight,
            this.NATURALE.position.z
        );
      }
      if (this.NAILHEADS) {
        this.NAILHEADS.position.set(
            this.NAILHEADS.position.x,
            baseHeight,
            this.NAILHEADS.position.z
        );
      }
      if (this.TOP) {
        Object.keys(this.TOP).forEach(key => {
          this.TOP[key].position.set(
              this.TOP[key].position.x,
              baseHeight,
              this.TOP[key].position.z
          );
        });
      }
      if (this.TOPCLONE) {
        Object.keys(this.TOPCLONE).forEach(key => {
          this.TOPCLONE[key].position.set(
              this.TOPCLONE[key].position.x,
              baseHeight,
              this.TOPCLONE[key].position.z
          );
        });
      }
      if (Object.keys(this.LEAVE['112']).length > 0) {
        Object.keys(this.LEAVE).forEach(leaf => {
          Object.keys(this.LEAVE[leaf]).forEach(edge => {
            this.LEAVE[leaf][edge].position.set(
                this.LEAVE[leaf][edge].position.x,
                baseHeight,
                this.LEAVE[leaf][edge].position.z
            );
            this.LEAVE[leaf]['apron'].position.set(
                this.LEAVE[leaf]['apron'].position.x,
                baseHeight,
                this.LEAVE[leaf]['apron'].position.z
            );
          });
        });
      }
    },
    /**
     * Take a screenshot from 3D scene to be uploaded into local storage.
     */
    takeScreenshot() {
      this.render();
      this.renderer.domElement.toBlob((blob) => {
        saveBlob(blob, `Screen Shot ${new Date()}.png`);
      });

      const saveBlob = (function () {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        return function saveData(blob, fileName) {
          a.href = window.URL.createObjectURL(blob);
          a.download = fileName;
          a.click();
        };
      }());
    },
    /**
     * Load a texture from a given URL.
     * This function is responsible for fetching the texture data and updating the model's appearance.
     *
     * @param {string} url - The path to the texture.
     * @return {*} The loaded texture data.
     */
    textureLoad(url) {
      this.isSwatchExists = true;
      return this.textureLoader.load(url,
        () => {
          // Texture loaded successfully
          this.isLoading = false;
        },
        (xhr) => {
          // Texture is loading
          this.loadingAmount = xhr.loaded;
          this.loadingTotal = xhr.total;
          this.isSwatchExists = true;
          this.isLoading = true;
        },
        () => {
          // Error occurred while loading texture
          this.isSwatchExists = false;
          this.isLoading = false;
        });
    },
    /**
     *
     * @param {string} edge - Table edge, default is EA.
     */
    toggleEdge(edge) {
      if (this.TOP) {
        Object.keys(this.TOP).forEach(key => this.TOP[key].visible = edge === key);
      }
      if (this.TOPCLONE) {
        Object.keys(this.TOPCLONE).forEach(key => this.TOPCLONE[key].visible = edge === key);
      }
      this.toggleLeaf();
    },
    /**
     * Toggle case knob ['K749', '1586-VB', '1586-WID', ...]
     * @param {string} type - Hardware type.
     * @param {string} df - DESC_FLAG.
     */
    toggleKnob(type, df) {

      console.log("toggleKnob");

      this.knob = df.replace(/-/g, '');
      if (this.HARDWARE[type]) {
        Object.keys(this.HARDWARE[type]).forEach(key => this.HARDWARE[type][key].visible = this.knob === key);
      }
      this.HARDWARE['DRAWER']['knob'].visible = df !== '-'; // occasional hardware
    },
    /**
     * Toggle table leave ['solid', '1-12in', '1-18in', '2-12in']
     */
    toggleLeaf() {
      if (Object.keys(this.LEAVE['112']).length > 0) {
        Object.keys(this.LEAVE).forEach(leaf => {
          Object.keys(this.LEAVE[leaf]).forEach(edge => {
            this.LEAVE[leaf][edge].visible = (leaf === this.leaf && edge === this.edge);
            this.LEAVE[leaf]['apron'].visible = this.leaf === leaf;
          });
        });
      }
    },
    /**
     * Toggle visibility of table legs and pedestals based on the provided option identifier.
     *
     * @param {string} df - Option Identifier.
     */
    toggleLeg(df) {
      // Remove pedestal if not PG2 and exists in the scene
      if (this.ped !== 'PG2' && this.PED_CLONE['P100']) {
        this.scene.remove(this.PED_CLONE['P100']);
        delete this.PED_CLONE['P100'];
      }
      // Toggle leg visibility based on the current leg selection
      if (this.LEG[0].length > 0) {
        for (let i = 0; i < 4; i++) {
          Object.keys(this.LEG[i]).forEach((key) => this.LEG[i][key].visible = this.leg === key);
        }
      }
      // Toggle pedestal visibility if not Sydney Storage Base
      if (this.PED && df !== 'P2SS') {
        Object.keys(this.PED).forEach((key) => this.PED[key].visible = this.ped === key);
      }
      // Handle visibility for options starting with 'M' or 'P'
      if (this.LEG[0].length > 0 && ['M', 'P'].includes(df.substring(0, 1))) {
        for (let i = 0; i < 4; i++) {
          this.LEG[i][this.leg].visible = false;
        }
      } else {
        if (this.PED && df !== 'P2SS') {
          Object.keys(this.PED).forEach((key) => this.PED[key].visible = false);
        }
        if (this.PLANE) {
          Object.keys(this.PLANE).forEach((key) => this.PLANE[key].visible = false);
        }
      }
    },
    /**
     * Toggle Arm/Side part
     *
     * @param {string} val - Option label
     */
    togglePart(val) {
      if (this.ARMS) {
        this.ARMS.visible = val.toLowerCase() === 'arm' || val === '02' || val === '12';
      }

      return true;
    },
    /**
     * Update camera and control settings based on the product line.
     * Different product lines might require different render settings for optimal visualization.
     */
    updateRenderSettings() {
      const fov = this.productLine === PRODUCT_LINES.OCCASIONAL ? 30 : 50;
      // update camera and control settings
      const cameraSetting = {
        aspect: 1,
        far: 200,
        fov,
        near: 0.1,
        position: {
          x: 2.0,
          y: 0.6,
          z: -4.7,
        },
        zoom: 1,
      };
      const controlSettings = {
        target: {
          x: 0,
          y: 1,
          z: 0,
        },
        maxPolarAngle: Math.PI / 3,
        minPolarAngle: Math.PI / 3,
      };

      try {
        if (this.camera && this.controls) {
          this.camera.fov = cameraSetting.fov;
          this.camera.near = cameraSetting.near;
          this.camera.zoom = cameraSetting.zoom;
          this.camera.position.set(
            cameraSetting.position.x,
            cameraSetting.position.y,
            cameraSetting.position.z,
          );
          this.camera.updateProjectionMatrix();

          this.controls.target.set(
            controlSettings.target.x,
            controlSettings.target.y,
            controlSettings.target.x,
          );
          this.controls.maxPolarAngle = controlSettings.maxPolarAngle;
          this.controls.minPolarAngle = controlSettings.minPolarAngle;
          this.controls.update();
        }
      } catch (e) {
        console.warn(`ModelRender | updateRenderSettings | ${e}`);
      }
    },
    /**
     * To resolve the memory leak, freedom of states resources on the component.
     */
    unmountScene() {
      window.cancelAnimationFrame(this.animateId); // Stop the animation
      this.animateId = undefined;
      this.controls.dispose();
      this.controls.autoRotate = false;
      this.controls = null;
      this.renderer.forceContextLoss();
      this.renderer = null;
      this.camera = null;
      this.cameraControls = null;
      this.container = null;
      this.model = null;
      this.scene = null;
    },
  },
};

</script>
<style></style>
