// Constants for repeated strings

export const SELECT_LABELS = {
  SELECT_LINE:                    'Select Line',
  SELECT_SHAPE:                   'Select Shape',
  SELECT_TABLE_SHAPE:             'Select Table Shape',
  SELECT_LEAF_OPTION:             'Select Leaf Option',
  SELECT_LEAVES:                  'Select Leaves',
  SELECT_EDGE:                    'Select Edge',
  SELECT_TABLE_SIZE:              'Select Table Size',
  SELECT_ARM_OR_SIDE:             'Select Arm Or Side',
  SELECT_STYLE:                   'Select Style',
  SELECT_BEDROOM_MAKE:            'Select Bedroom Make',
  SELECT_TOP_PROFILE:             'Select Top Profile',
  SELECT_CASE_MAKE:               'Select Case Make',
  SELECT_OCCASIONAL_MAKE:         'Select Occasional Make',
  SELECT_BASE:                    'Select Base',
  SELECT_LENGTH:                  'Select Length',
  SELECT_CASE_MODEL:              'Select Case Model',
  SELECT_BEDROOM_MODEL:           'Select Bedroom Model',
  SELECT_OCCASIONAL_MODEL:        'Select Occasional Model',
  SELECT_MIRROR_MODEL:            'Select Mirror Model',
  SELECT_SPECIES:                 'Select Species',
  SELECT_BASE_SPECIES:            'Select Base Species', // only for copper, zinc top table
  SELECT_HARDWARE:                'Select Hardware',
  SELECT_CORNER_HARDWARE:         'Select Corner Hardware',
  SELECT_DECO_HARDWARE:           'Select Deco Hardware',
  SELECT_DOOR_HARDWARE:           'Select Door Hardware',
  SELECT_DRAWER_HARDWARE:         'Select Drawer Hardware',
  SELECT_OCCASIONAL_HARDWARE:     'Select Occasional Hardware',
  SELECT_TOP_DRAWER_HARDWARE:     'Select Top Drawer Hardware',
  SELECT_MIDDLE_DRAWER_HARDWARE:  'Select Middle Drawer Hardware',
  SELECT_SEAT_TYPE:               'Select Seat Type',
  SELECT_SEAT_AND_BACK_OPTION:    'Select Seat and Back Option',
  SELECT_UPHOLSTERY:              'Select Upholstery',
  SELECT_FABRIC:                  'Select Fabric',
  SELECT_FINISH_OPTION:           'Select Finish Option',
  SELECT_FINISH_OPTIONS:          'Select Finish Options',
  SELECT_FINISH:                  'Select Finish',
  SELECT_FINISH_ACCENT:           'Select Finish Accent',
  SELECT_SEAT_FINISH:             'Select Seat Finish',
  SELECT_ACCENT_FINISH:           'Select Accent Finish',
  SELECT_FIRST_FINISH:            'Select First Finish',
  SELECT_TOP_FINISH:              'Select Top Finish',
  SELECT_DOORS_ONLY:              'Select Doors Finish',
  SELECT_DOORS_AND_DRAWERS_ONLY:  'Select Doors & Drawers Finish',
  SELECT_DRAWERS_ONLY:            'Select Drawers Finish',
  SELECT_FLOATING_PANEL_ONLY:     'Select Floating Panel Finish',
  SELECT_FLOATING_PANEL_DRAWERS:  'Select Floating Panel /Drawers Finish',
  SELECT_SEAT_AND_TOP_RAIL:       'Select Seat & Top Rail Finish',
  SELECT_TOP_AND_DRAWERS:         'Select Top & Drawers Finish',
  SELECT_TOP_AND_DOORS:           'Select Top & Doors Finish',
  SELECT_TOP_RAIL_ONLY:           'Select Top Rail Finish',
  SELECT_TOP_DOORS_DRAWERS:       'Select Top, Doors, & Drawers Finish',
  SELECT_TOP_DOORS_DRAWERS_C:     'Select Top, Doors & Drawers Finish',
  SELECT_FRAME_FINISH:            'Select Frame Finish',
  SELECT_BODY_FINISH:             'Select Body Finish',
  SELECT_SECOND_FINISH:           'Select Second Finish',
  SELECT_BASE_FINISH:             'Select Base Finish',
  SELECT_NAIL_HEAD:               'Select Nail Head',
  SELECT_NAILHEAD:                'Select Nailhead',
  SELECT_INLAY:                   'Select Inlay',
  SELECT_HEADBOARD_AND_FOOTBOARD_PANEL: 'Select Headboard and Footboard Panel Finish',
  SELECT_HEADBOARD_PANEL_ONLY: 'Select Headboard Panel Finish',
  SELECT_HEADBOARD_FOOTBOARD_PANEL_AND_DRAWERS:'Select Headboard, Footboard Panel & Drawer Finish',
  SELECT_INSIDE_FINISH:           'Select Inside Finish'
};

export const FINISH_OPTIONS = {
  TWO_TONE:               '2TT- Two Tone',
  TWO_TONE_DOOR_ONLY:     'Two Tone (Doors Only)',
  TWO_TONE_TOP_ONLY:      'Two Tone (Top Only)',
  TWO_TONE_TOP_DOOR:      'Two Tone (Top & Doors)',
  SOLID_PAINT:            'PNT- Solid Paint',
  SOLID_PAINT_BASE:       'PNT- Solid Paint (Base)',
  SOLID_PAINT_BASE_C:     'PNT- Solid Paint Base', // copper top table
  SOLID_STAIN:            'STN- Solid Stain',
  SOLID_STAIN_BASE:       'STN- Solid Stain (Base)',
  SOLID_STAIN_BASE_C:     'STN- Solid Stain Base', // copper top table
  DOORS_ONLY:             'Doors',
  DOORS_AND_DRAWERS_ONLY: 'Doors & Drawers',
  DRAWERS_ONLY:           'Drawers',
  FLOATING_PANEL_ONLY:    'Floating Panel',
  FLOATING_PANEL_DRAWERS: 'Floating Panel _Drawers',
  SEAT_ONLY:              'Seat',
  SEAT_AND_TOP_RAIL:      'Seat & Top Rail',
  TOP_ONLY:               'Top',
  TOP_AND_DRAWERS:        'Top & Drawers',
  TOP_AND_DOORS:          'Top & Doors',
  TOP_RAIL_ONLY:          'Top Rail',
  TOP_DOORS_AND_DRAWERS:  'Top, Doors, & Drawers',
  TOP_DOORS_AND_DRAWERS_C:'Top, Doors & Drawers',
  HEADBOARD_PANEL_ONLY: 'Headboard Panel',
  HEADBOARD_AND_FOOTBOARD_PANEL:'Headboard and Footboard Panel',
  HEADBOARD_FOOTBOARD_PANEL_AND_DRAWERS:'Headboard, Footboard Panel & Drawer',
  INSIDE_ONLY:            'Inside',
  BODY_ONLY:              'Body'
};


export const UPHOLSTERY_OPTIONS = {
  FABRIC_SEAT:            'Fabric Seat',
  FABRIC_SEAT_BACK:       'Fabric Seat & Back',
  LEATHER_SEAT:           'Leather Seat',
  LEATHER_SEAT_BACK:      'Leather Seat & Back',
  WOOD_SEAT:              'Wood Seat',
  WOOD_SEAT_FABRIC_BACK:  'Wood Seat & Fabric Back',
  WOOD_SEAT_LEATHER_BACK: 'Wood Seat & Leather Back',
};

export const PRODUCT_LINES = {
  TABLE:        'table',
  BARSTOOL:     'barstool',
  BEDROOM:      'bedroom',
  CHAIR:        'chair',
  BENCHES:      'benches',
  DINING_CASES: 'dining cases',
  OCCASIONAL:   'occasional',
  // HOME_OFFICE:  'home office',
  DESK:         'desk',
  CABINET:      'cabinet',
  BOOKCASE:     'bookcase'
};

export const TEXTURE_PATHS = {
  DEFAULT:      '/images/textures',
  BIRCH_ARCH:   '/images/textures/Birch_Arch',
  CORNER:       '/images/textures/Corner',
  ELM:          '/images/textures/Elm',
  MAPLE:        '/images/textures/Maple',
  NAIL_HEADS:   '/images/textures/nailhead',
  PAINT:        '/images/textures/Paint',
  SPECIES:      '/images/textures/Species',
  UPHOLSTERY:   '/images/textures/Upholstery',
  WALNUT_INLAY: '/images/textures/Walnut_Inlay',
};
